import React, { useState } from 'react'
// import './css/modal-dialog-box.css'
// import './js/modal-dialog-box.js'
import { Modal } from 'antd';

interface Props {
    children: any,
    isModalVisible: boolean,
    showFooter?: boolean,
    handleOk: () => void,
    handleCancel: () => void,
    bodyStyle?: object 
}

export default function Modal2(props: Props) {
    return (
        <>
            <Modal
                // title="Basic Modal"
                visible={props?.isModalVisible}
                onOk={props?.handleOk}
                onCancel={props?.handleCancel}
                footer={props?.showFooter}
                // style={{ borderRadius: 20 }}
                bodyStyle={props?.bodyStyle}
            >
                {props?.children}
            </Modal>
        </>
    )
}