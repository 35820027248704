import React, { useState, useEffect } from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import Dropdown from '../../components/dropdown/dropdown';
import './css/banking-details.css'
import Loader from '../../components/loader/loader';
import axios from 'axios';
import Toast from '../../components/Toast/toast';

interface Props {
    returningUser?: boolean,
    loading: boolean,
    banks: any[],
    closeModal: (data: boolean) => void,
}

// export const BankingDetails: React.FC<Props> = ({returningUser}) => {
export default function BankingDetails(props: Props) {
    const {
        loading,
        banks,
        closeModal,
    } = props;

    const [processing, setProcessing] = useState(false);
    const [resolving, setResolving] = useState(false);
    const [resolved, setResolved] = useState(false);
    const [resolvedData, setResolvedData] = useState({ account_name: "" });
    const [bankCode, setBankCode] = useState("");
    const [account, setAccount] = useState("");

    useEffect(() => {
        if (account) {
            const delayDebounceFn = setTimeout(() => {
                resolveAccount();
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [account])

    const resolveAccount = async () => {
        try {
            // setResolving(true);
            const payload = {
                "account": account,
                "settlementBank": bankCode
            }

            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/transfer/verify`, payload);
            console.log(res.data)
            if (res && res.data) {
                setResolvedData(res.data?.data);
                setResolved(true);
            }
            setResolving(false);
        } catch (error) {
            console.log(error.response)
            // Toast(error.response && error.response.data.message, "error");
        }
    }

    const saveDetails = async () => {
        try {
            setProcessing(true);
            const payload = {
                "bank_id":bankCode,
                "account_number": account
            }

            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/account`, payload);
            console.log(res.data)
            if (res && res.data) {
                Toast(res.data.message, "success");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                Toast(res.data.message, "error");
            }
            setProcessing(false);
        } catch (error) {
            console.log(error.response)
            // Toast(error.response && error.response.data.message, "error");
        }
    }

    return (
        <>
            <div className="bkde-central-area">
                <div className="text-area">
                    Save Banking Details
                </div>

                {loading && <>
                    <br />
                    <Loader className="loader-lg" />
                </>}

                {banks.length > 0 && <>
                    {/* <div className="iconed-label">
                        <TextInput labelName="Bank Verification Number" />
                        <div className="bkde-text-info-area">
                            <div>Simply dial *565*0# to check your BVN</div>
                            <div className="bkde-lock">
                                <i className="fas fa-lock"></i>
                            </div>
                        </div>
                    </div> */}
                    <div className="bkde-text-form-area">
                        {/* <TextInput labelName="Select Bank" /> */}
                        <Dropdown
                            label="Select Bank"
                            valueKey="bankCode"
                            displayKey="bankName"
                            data={banks}
                            onInput={(e: any) => setBankCode(e.target.value)}
                        />
                        {/* <br /> */}
                        <TextInput labelName="Account Number" onInput={(e: any) => {
                            setResolving(true);
                            setResolved(false);
                            setAccount(e.target.value)}
                        } />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: 10 }}>{resolving && <Loader className="loader-md" />}</div>
                            <div className="resolved-text">{resolvedData && resolvedData.account_name}</div>
                        </div>
                    </div>
                    <br />
                    <div className="bkde-submit-button">
                        <div className="first-btn">
                            <Button btn_name="Cancel" color="btn-default" onClick={() => closeModal(true)} />
                        </div>
                        <div className="second-btn">
                            <Button
                                btn_name="Save Details"
                                color="btn-blue"
                                loading={processing}
                                disabled={!resolved}
                                onClick={saveDetails}
                            />
                        </div>
                    </div>
                </>}
            </div>
            {/* </div> */}
        </>
    );
}