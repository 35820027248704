import React, { useEffect, useState } from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import SettingsNav from '../../components/setting-nav/setting-nav';
import './css/settings.css';
import Card from '../../components/card/card';
import Dropdown from '../../components/dropdown/dropdown';
import UploadInput from '../../components/upload-input/upload-input';
import Profileimage from '../../components/profileimage/profileimage';
import Toast from '../../components/Toast/toast';

import imagep from "../../components/asset/imagescopy.jpg"




import {
    completeRegistration,
    fetchStates,
    fetchCityByStateId,
    fetchTransactions,
    fetchTransactionById,
    UniversalReq,
    updateProfile,
} from '../../actions/auth';
import { fetchUserDetails, saveDocument } from '../../actions/users';
import Loader from '../../components/loader/loader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import Security from './security';



const settingNavContent = [
    {
        "state": "settings-active",
        "name": "Profile Details",
        "path": "/settings-Profile Details"
    },
    {
        "state": "settings-inactive",
        "name": "Business Details",
        "path": "/settings-Business Details"
    },
    {
        "state": "settings-inactive",
        "name": "Banking Details",
        "path": "/settings-Banking Details"
    },
    {
        "state": "settings-inactive",
        "name": "Security",
        "path": "/settings-Security"
    }
]



// interface Props {
//     returningUser?: boolean
// }


// function ProfileDetails(props: any){
const ProfileDetails = (props: any) => {

    // console.log("from ProfileDetails : ", props)

    const [processing, setProcessing] = useState(false);
    const [full_name, setFullName] = useState(props?.users?.sa_details?.user_data?.name);
    const [address, setAddress] = useState(props?.users?.sa_details?.user_data?.address);
    const [phone, setPhone] = useState(props?.users?.sa_details?.phone_number);
    const [email, setEmail] = useState(props?.users?.sa_details?.email);
    const [allStates, setStates] = useState(props.auth.states);
    const [state, setState] = useState(props?.users?.sa_details?.user_data?.city.state.id);
    // const [allCities, setCities] = useState(props.auth.cities);
    const [allCities, setCities] = useState([]);
    const [city, setCity] = useState(props?.users?.sa_details?.user_data?.city.id);
    const [CityLabel, setCityLabel] = useState("")

    const [UpdateDPProcess, setUpdateDPProcess] = useState(false)
    const [UpdatedDP, setUpdatedDP] = useState("")

    const {
        fetchStates,
        fetchCityByStateId,
        auth: {
            loading,
            states,
            cities,
            universal_request
        }
    } = props;

   useEffect(() => {
        if(allCities?.length < 1){
            const waitingForCities = () => props.fetchCityByStateId(props?.users?.sa_details?.user_data?.city?.state.id)
            waitingForCities()
            setCities(cities)
        }
   }, [])


   useEffect(() => {
        try{
            setUpdatedDP(universal_request.photo_url)
        } catch(error) {

        }

        setUpdateDPProcess(false)
    }, [universal_request])


    useEffect(() => {
        if(cities){
            setCities(cities);
        }
    }, [cities])

    const saveProfileChange = async () => {
        setProcessing(true)

        const payload = {
            method: "post",
            // url: `${process.env.REACT_APP_BASE_URL}/superagent/profile/${props?.users?.sa_details?.user_type}`,
            url: `${process.env.REACT_APP_BASE_URL}/superagent/profile/${"business"}`,
            data: {
                // "user_id": props?.users?.sa_details?.account_id,
                "user_id": props?.users?.sa_details?.id,
                // "email":email,
                // "phone_number":phone,
                "name":full_name,
                "address": address,
                "city_id": city,
                "state_id": state
            }
        }

        const waitingProcess = async () => await props.updateProfile(payload);
        waitingProcess()

        console.log("PAYLOAD : ", payload)

        setProcessing(false)
    }

    const _fetchCityByStateId = (id: any) => {
        // console.log("id of city : ", id)

        setCity("")
        setCityLabel("City")

        props.fetchCityByStateId(id)
        // fetchCityByStateId(id);
    }

    // console.log("Cities in profile: ", allCities)

    return (
        <>
            {loading && <Loader className="loader-lg" />}
            {!loading &&
            <div className="settings-central-area settings-central-bg-color">


                <div className="left-column">
                    <div style={{width: 365}}>
                        <TextInput
                            onInput={(e: any) => setFullName(e.target.value)}
                            labelName="Full Name"
                            value={full_name}
                        />
                    </div>
                    <br/>
                    <div style={{width: 365}}>
                        <TextInput
                            disabled={true}
                            type="phone" labelName="Phone Number"
                            onInput={(e: any) => setPhone(e.target.value)}
                            value={phone}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <TextInput
                            disabled={true}
                            type="email" labelName="Email Address"
                            onInput={(e: any) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <TextInput
                            labelName="Street Address"
                            onInput={(e: any) => setAddress(e.target.value)}
                            value={address}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <Dropdown
                            label={props?.users?.sa_details?.user_data?.city?.state.name || "State"}
                            valueKey={"id"}
                            displayKey={"name"}
                            data={allStates}
                            onInput={(e: any) => {
                                setState(e.target.value)
                                _fetchCityByStateId(e.target.value)
                            }}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <Dropdown
                            label={CityLabel || props?.users?.sa_details?.user_data?.city.name || "City"}
                            valueKey={"id"}
                            displayKey={"name"}
                            data={allCities}
                            onInput={(e: any) => setCity(e.target.value)}
                        />
                    </div>

                    <br />
                    <div style={{ width: 365 }}>
                        <Button
                            btn_name="Save Profile"
                            color="btn-blue"
                            onClick={saveProfileChange}
                            loading={processing}
                        />
                    </div>

                    <div style={{ height: 50 }}>
                    </div>
                </div>

                <div className="right-column">
                    <UploadInput
                        getFile={e =>{
                            // console.log(e)

                            setUpdateDPProcess(true)

                            let formData = new FormData();
                            formData.append("file_upload", e)

                            const payload = {
                                notloading: true,
                                method: "post",
                                url: `${process.env.REACT_APP_BASE_URL}/superagent/profile/photo`,
                                'data': formData
                            }

                            const waitingUpload = async () => props.UniversalReq(payload);
                            waitingUpload()

                        }}
                        accept="image/*"
                        id="CHANGE PROFILE PHOTO"
                        type="file"
                        appearance="upload-profile-image"
                        labelName="CHANGE PROFILE PHOTO"
                    />
                    <Profileimage loading={UpdateDPProcess} image={UpdatedDP || props?.users?.sa_details?.photo_url} />
                </div>
            </div>
            }
        </>
    )
}






const BusinessDetails = (props: any) => {

    // console.log("from BusinessDetails : ", props)

    const [processing, setProcessing] = useState(false);
    const [rcNumber, setRCNumber] = useState("");
    const [role, setRole] = useState("");
    const [cacDoc, setCACDoc] = useState("");
    const [BEmail, setBEmail] = useState(props?.users?.sa_details?.email);
    // const [businessName, setBusinessName] = useState("");
    const [companyAddress, setCompanyAddress] = useState(props?.users?.sa_details?.user_data?.address);
    const [allStates, setStates] = useState(props.auth.states);
    const [state, setState] = useState(props?.users?.sa_details?.user_data?.city?.state.id);
    const [allCities, setCities] = useState(() => []);
    const [city, setCity] = useState(props?.users?.sa_details?.user_data?.city?.id);

    const [CityLabel, setCityLabel] = useState("")

    const [UpdateDPProcess, setUpdateDPProcess] = useState(false)
    const [UpdatedDP, setUpdatedDP] = useState("")


    const {
        fetchStates,
        fetchCityByStateId,
        saveDocument,
        auth: {
            loading,
            states,
            cities,
            universal_request
        }
    } = props;



    useEffect(() => {
        if(allCities?.length < 1){
            const waitingForCities = () => props.fetchCityByStateId(props?.users?.sa_details?.user_data?.city?.state.id)
            waitingForCities()
            setCities(cities)
        }
    }, [])



    useEffect(() => {
        if(cities){
            setCities(cities);
        }
    }, [cities])



    useEffect(() => {
        // console.log("Return universal : ", universal_request)

        try{
            setUpdatedDP(universal_request.photo_url)
        } catch(error) {

        }

        setUpdateDPProcess(false)
    }, [universal_request])



    const saveBusinessDetails = async () => {
        setProcessing(true);
        // console.log("setProcessing : ", processing)

        let formData = new FormData();
        formData.append("business_name", props?.users?.sa_details?.business_doc?.business_name);
        if(rcNumber){
            formData.append("business_number", rcNumber);
        }else {
            Toast("No business number", "error");
            setProcessing(false);
            return
        }

        if(cacDoc){
            formData.append("cac_files", cacDoc);
        }else {
            Toast("No CAC document", "error");
            setProcessing(false);
            return
        }

        if(role){
            formData.append("role", role);
        }else {
            Toast("No role", "error");
            setProcessing(false);
            return
        }

        if(companyAddress){
            formData.append("company_address", companyAddress);
        }else {
            Toast("No address", "error");
            setProcessing(false);
            return
        }

        if(city){
            formData.append("city_id", city);
        }else {
            Toast("No city", "error");
            setProcessing(false);
            return
        }

        if(state){
            formData.append("state_id", state);
        }else {
            Toast("No state", "error");
            setProcessing(false);
            return
        }

        // await saveDocument(formData);


        const payload = {
            notloading: true,
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/superagent/business/documents`,
            data: formData
        }

        // saveDocument(formData);

        // const waitingSaveDocument = async () => props.UniversalReq(payload);

        const waitingSaveDocument = async () => await props.saveDocument(formData);
        waitingSaveDocument()

        console.log("waitingSaveDocument : ", payload)



        setProcessing(false);
    }


    const _fetchCityByStateId = (id: any) => {
        // setCities([]);
        // console.log("id of city : ", id)
        setCity("")
        setCityLabel("City")

        props.fetchCityByStateId(id)
        // fetchCityByStateId(id);

    }


    return (
        <>
            {loading && <Loader className="loader-lg" />}
            {!loading &&
            <div className="settings-central-area settings-central-bg-color">



                <div className="left-column">
                    <div style={{width: 365}}>
                        <TextInput
                            labelName="RC or BN Number"
                            onInput={(e: any) => setRCNumber(e.target.value)}
                        />
                    </div>

                    <div style={{ width: 365, marginBottom: 10 }}>
                        <div className="setting-business-file">
                            Upload CAC Documents
                        </div>
                    </div>

                    <div style={{width: 365}}>
                        {/* <TextInput labelName="CERT.pdf" /> */}

                        <UploadInput
                            labelName="Cert Upload"
                            id="Cert Upload"
                            type="file"
                            appearance="dotted-fade"
                            getFile={e => {
                                // console.log(e)
                                setCACDoc(e)
                            }}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <Dropdown
                            label="Your Role"
                            valueKey={"id"}
                            displayKey={"name"}
                            data={[{ id: 1, name: "Business Owner" }, { id: 2, name: "Admin" }, { id: 3, name: "Developer" }]}
                            onInput={(e: any) => setRole(e.target.value)}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <TextInput
                            type="email"
                            labelName="Business Email"
                            onInput={(e: any) => setBEmail(e.target.value)}
                            value={BEmail}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <TextInput
                            labelName="Company Address"
                            onInput={(e: any) => setCompanyAddress(e.target.value)}
                        />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <Dropdown
                                label="State"
                                valueKey={"id"}
                                displayKey={"name"}
                                data={allStates}
                                onInput={(e: any) => {
                                    setState(e.target.value)
                                    _fetchCityByStateId(e.target.value)
                                }}
                            />
                    </div>

                    <br/>
                    <div style={{width: 365}}>
                        <Dropdown
                            label={CityLabel || props?.users?.sa_details?.user_data?.city?.name || "City"}
                            valueKey={"id"}
                            displayKey={"name"}
                            data={allCities}
                            onInput={(e: any) => setCity(e.target.value)}
                        />
                    </div>

                    <br />
                    <div style={{ width: 365 }}>
                        <Button
                            btn_name="Save Business Details"
                            color="btn-blue"
                            loading={processing}
                            onClick={saveBusinessDetails}
                        />
                    </div>

                    <div style={{ height: 50 }}>
                    </div>


                </div>

                <div className="right-column">
                    <UploadInput
                        getFile={e =>{
                            // console.log(e)

                            setUpdateDPProcess(true)

                            let formData = new FormData();
                            formData.append("file_upload", e)

                            const payload = {
                                notloading: true,
                                method: "post",
                                url: `${process.env.REACT_APP_BASE_URL}/superagent/profile/photo`,
                                'data': formData
                            }

                            const waitingUpload = async () => props.UniversalReq(payload);
                            waitingUpload()

                        }}
                        accept="image/*"
                        id="CHANGE PROFILE PHOTO"
                        type="file"
                        appearance="upload-profile-image"
                        labelName="CHANGE PROFILE PHOTO"
                    />
                    <Profileimage loading={UpdateDPProcess} image={UpdatedDP || props?.users?.sa_details?.photo_url} />
                </div>
            </div>
            }
        </>
    )
}


const BankingDetails = (props: any) => {

    const [Processing, setProcessing] = useState(false)
    const [resolving, setResolving] = useState(false);
    const [resolved, setResolved] = useState(false);
    const [resolvedData, setResolvedData] = useState({ account_name: "" });
    // const [WantToSave, setWantToSave] = useState(false)

    const [Banks, setBanks] = useState([])
    const [BankCode, setBankCode] = useState("")
    const [Account, setAccount] = useState("")
    const [FetchingBanks, setFetchingBanks] = useState(false)


    useEffect(() => {
        if (Account) {
            const delayDebounceFn = setTimeout(() => {
                resolveAccount();
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [Account])

    const resolveAccount = async () => {
        try {
            // setResolving(true);
            const payload = {
                "account": Account,
                "settlementBank": BankCode
            }

            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/transfer/verify`, payload);
            // console.log(res.data)
            if (res && res.data) {
                setResolvedData(res.data?.data);
                setResolved(true);
                // if(WantToSave){
                //     saveBankingDetails()
                // }
            }
            setResolving(false);
        } catch (error) {
            console.log(error.response)
            // if(WantToSave){
            //     saveBankingDetails()
            // }
        }
    }




    const fetchBanks = async () => {
        try {
            setFetchingBanks(true);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/transfer/banks`);


            if (res && res.data) {
                setBanks(res.data?.data);
            }
            setFetchingBanks(false);
        } catch (error) {
            console.log(error.response)
            Toast(error.response && error.response.data.message, "error");
        }
    }

    useEffect(()=>{
        fetchBanks()
    }, [])

    const saveBankingDetails = async () => {
        // if(!Processing){
        //     setProcessing(true);
        //     setResolved(false);
        //     resolveAccount();
        //     return
        // }

        try {
            // if(resolved && Processing){
            //     setWantToSave(false)
            // }else{
            //     setProcessing(false);
            //     Toast("Account not found", "error");
            //     return
            // }


            const payload = {
                "bank_id":BankCode,
                "account_number": Account
            }

            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/account`, payload);
            // console.log("SETTING BANKS: ", res.data)
            if (res && res.data) {
                Toast(res.data.message, "success");
                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000);
            } else {
                Toast(res.data.message, "error");
            }
            setProcessing(false);
        } catch (error) {
            console.log(error.response)
            setProcessing(false);
            // Toast(error.response && error.response.data.message, "error");
        }
    }


    return (
        <>
            <div className="BK-SE-settings-central-area">
                <div style={{ width: 373 }}>
                {!FetchingBanks && <Dropdown
                            label="Select Bank"
                            valueKey={"bankCode"}
                            displayKey={"bankName"}
                            data={Banks}
                            onInput={(e: any) => setBankCode(e.target.value)}
                        />
                }
                {FetchingBanks && <Loader className="loader-md" />}
                </div>

                <br />

                <div style={{ width: 373 }}>
                    <TextInput
                        labelName="Account Number"
                        onInput={(e: any) => {
                            setResolving(true);
                            setResolved(false);
                            setAccount(e.target.value)
                        }}
                    />
                </div>

                <br />

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ marginRight: 10 }}>{resolving && <Loader className="loader-md" />}</div>
                    <div>{resolvedData && resolvedData.account_name || ""}</div>
                </div>

                <br />
                <div style={{ width: 373 }}>
                    <Button
                        btn_name="Save Payout Details"
                        color="btn-blue"
                        loading={Processing}
                        disabled={!resolved}
                        onClick={() => {
                            // setWantToSave(true)
                            saveBankingDetails()
                        }}
                    />
                </div>
            </div>
        </>
    )
}



interface Props {
    returningUser?: boolean,
    fetchStates: () => void,
    fetchCityByStateId: (id: string) => void,
    fetchUserDetails: () => void,
    fetchTransactions: () => void,
    fetchTransactionById: (agentId: string) => void,
    UniversalReq: (payloadObj: any) => void,
    updateProfile: (payloadObj: any) => void,
    saveDocument: (payload: any) => void,
    auth: AuthProps,
    users: UserProps
}

interface AuthProps {
    loading: boolean,
    states?: React.SetStateAction<never[]>,
    cities?: React.SetStateAction<never[]>,
    transactions?: React.SetStateAction<never[]>,
    transactions_id?: React.SetStateAction<never[]>,
    universal_request?: React.SetStateAction<never[]>,
}

interface UserProps {
    sa_details?: any,
}

// const settingPagesArray = [<ProfileDetails />, <BusinessDetails />, <BankingDetails />, <Security />]

function Settings(props: Props){
    const [settingPage, setsettingPage] = useState(() => 0)
    const [Adhoc_sa_details, setAdhoc_sa_details] = useState(false)
    // const [GetUserDetails, setGetUserDetails] = useState(false)


    const {
        fetchStates,
        fetchCityByStateId,
        fetchUserDetails,
        saveDocument,
        fetchTransactions,
        fetchTransactionById,
        UniversalReq,
        updateProfile,
        auth: {
            loading,
            states,
            cities,
            transactions,
            transactions_id,
            universal_request,
        },
        users: {
            sa_details
        },
    } = props;

    const waitForFetch_sa_details = async () => await fetchUserDetails();
    const waitForFetch_states = async () => await fetchStates();
    const waitForFetch_cities = async () => {
        if(sa_details){
            await fetchCityByStateId(sa_details.user_data?.city?.state.id)
        }
    }

    useEffect(() => {
        // console.log("Fetch Details Bootstrap");


        // try {
        // if(!cities){ waitForFetch_cities();}
        // } catch (error) {

        // }


        if(!sa_details){ waitForFetch_sa_details();}

        if(!states){
            waitForFetch_states();
        }


        const waitingForSetting = async() => await setAdhoc_sa_details(sa_details && states && cities ? true : false);
        waitingForSetting()
        setAdhoc_sa_details(sa_details && states && cities ? true : false);
    }, [])

    useEffect(() => {

        // console.log("Recalling the fetchDetails")
        if(sa_details?.id == undefined || sa_details?.id == false){



            // try {
                // if(!cities){ waitForFetch_cities();}
            // } catch (error) {

            // }


            if(!sa_details){ waitForFetch_sa_details()}

            if(!states){ waitForFetch_states();}


            const waitingForSetting = async() => await setAdhoc_sa_details(sa_details && states && cities ? true : false);
            waitingForSetting()
            setAdhoc_sa_details(sa_details && states && cities ? true : false);
        }

    }, [loading])





    // console.log("settings", loading)
    // console.log("states", states)
    // console.log("cities", cities)
    // console.log("user", sa_details)
    // console.log("user_adhoc", Adhoc_sa_details)


    return (
        <>
            <div className="settings-box-management">

                <SettingsNav navContent={settingNavContent} handleClick={index => {
                    console.log(index)
                    setsettingPage(prev => index)
                }} />

                <div className="settings-pseudo-body">
                    {/* {loading && !Adhoc_sa_details && <Loader className="loader-lg" />} */}
                    {loading && !sa_details && !states && <Loader className="loader-lg" />}
                    {!loading && sa_details && states && <>
                        {settingPage == 0 &&
                            <ProfileDetails
                                {...props}/>
                        }
                        {settingPage == 1 &&
                            <BusinessDetails {...props}/>
                        }
                        {settingPage == 2 &&
                            <BankingDetails {...props}/>
                        }
                        {settingPage == 3 &&
                            <Security {...props}/>
                        }

                        </>
                    }
                </div>




                <br />
                <br />


            </div>


        </>
    );
}


function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        users: state.users
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    completeRegistration,
    fetchStates,
    fetchCityByStateId,
    fetchUserDetails,
    fetchTransactions,
    fetchTransactionById,
    UniversalReq,
    saveDocument,
    updateProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
