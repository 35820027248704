import React, { useState } from 'react';
import './styles.css';
import jwt_decode from "jwt-decode";
import Button from '../button/button';
// const logo = require("../../assets/imgs/logoicon@3x.png");

var decoded = {};
const token = localStorage.getItem("TOKEN");

if (token) {
    decoded = jwt_decode(token);
}

const navigate = (route) => window.location.href = `/${route}`;

const openNav = () => {
    document.getElementById("openNav").style.display = "none";
    document.getElementById("closeNav").style.display = "block";
    document.getElementById("myNav").style.width = "100%";
}

const closeNav = () => {
    document.getElementById("openNav").style.display = "block";
    document.getElementById("closeNav").style.display = "none";
    document.getElementById("myNav").style.width = "0%";
}

const logout = () => {
    localStorage.clear("TOKEN");
    setTimeout(() => {
        window.location.href = "/login";
    }, 1000);
}

export default function (props) {
    const toggleShow = () => props.toggle();
    const title = localStorage.getItem("title");
    const path1 = window.location.pathname.split("/")[1];

    return <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom main-navbar">
        <div id="myNav" className="overlay">
            <span id="closeNav" className="closeNav" onClick={closeNav}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
                    <title>noun_X_2222051</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Hamburger-Menu-expanded" transform="translate(-335.000000, -12.000000)" fill="#000000">
                            <g id="noun_X_2222051" transform="translate(335.000000, 12.000000)">
                                <polygon id="Path" points="8 5.94059406 13.9405941 0 16 2.07920792 10.0792079 8 16 13.9405941 13.9405941 16 8 10.0792079 2.07920792 16 0 13.9405941 5.94059406 8 0 2.07920792 2.07920792 0" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>

            {/* javascript:void(0); */}

            {/* Overlay content */}
            <div className="overlay-content">
                <a href="#" onClick={() => window.location.href = "/overview"}>Dashboard</a>
                <a href="#" onClick={() => window.location.href = "/commissions"}>Commissions</a>
                <a href="#" onClick={() => window.location.href = "/merchants"}>Merchants</a>
                <a href="#" onClick={() => window.location.href = "/transactions"}>Transactions</a>
                <a href="#" onClick={() => window.location.href = "/settings"}>Settings</a>
                <a className="logout-text" href="#" onClick={logout}>Logout</a>
            </div>
        </div>

        <div>
            <span id="openNav" className="openNav" onClick={openNav}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
                    <title>noun_menu_2984382</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Home-mobile" transform="translate(-343.000000, -12.000000)" fill="#000000">
                            <g id="noun_menu_2984382" transform="translate(343.000000, 12.000000)">
                                <polygon id="Path" points="0 15.36 19.84 15.36 19.84 13.44 0 13.44" />
                                <polygon id="Path" points="0 8.32 19.84 8.32 19.84 6.4 0 6.4" />
                                <polygon id="Path" points="0 0 0 1.92 19.84 1.92 19.84 0" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
        </div>

        <div className="navbar-brand">
            <div className="page-description">
                <i onClick={toggleShow} className="fas fa-align-right fa-flip-vertical fa-flip-horizontal"></i>
                <div>{path1 === "verify-account" ? `Welcome, ${localStorage.getItem("username") || "user"}! ☀️` : title}</div>
            </div>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-search">
                    {/* <input
                        className="form-control mr-sm-2"
                        type="search"
                        placeholder="Search for transactions using reference or amount..."
                        aria-label="Search"
                    /> */}
                    {/* <a className="" href="#">Search for transactions using reference or amount... </a> */}
                </li>
            </div>

            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <div className="buttons">
                    <Button
                        btn_name="Logout"
                        onClick={logout}
                        color="btn-logout"
                    />
                </div>
            </ul>
        </div>
    </nav>
}