import React, { useState } from 'react'
import './css/otp.css'

interface Props {
  setVal: (e: any) => void
}

export default function Otp(props: Props) {
  const [currID, setCurrID] = useState("");

  const moveCursor = (newId: string) => {
    document.getElementById(newId)?.focus();
    setCurrID(newId);
    setInput();
  }

  var input = document.getElementById('myInput');

  if (input) {
    input.addEventListener('keydown', function (event) {
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        return false;
      }
    });
  }

  const setInput = () => {
    let currVal = "";
    ["one", "two", "three", "four", "five", "six"].map(e => {
      let f = (document.getElementById(e) as HTMLInputElement).value;
      currVal = f ? currVal.concat(f) : currVal.concat("");
    })
    props.setVal(currVal);
  }

  const myKeyPress = (e: any) => {
    var keynum;

    if (window.event) { // IE                  
      keynum = e.keyCode;
    } else if (e.which) { // Netscape/Firefox/Opera                 
      keynum = e.which;
    }

    if (keynum === 8) {
      // alert(document.activeElement?.id)
      // document.getElementById("one")?.innerHTML = "";
      setTimeout(() => {
        // document.getElementById("one")?.focus()
        delValAndFocus();
        setInput();
      }, 0);
    }
    // alert(String.fromCharCode(keynum));
  }

  const delValAndFocus = () => {
    // alert(currID)
    switch (currID) {
      case "six":
        document.getElementById("five")?.focus();
        setCurrID("five")
        break;
      case "five":
        document.getElementById("four")?.focus();
        setCurrID("four")
        break;
      case "four":
        document.getElementById("three")?.focus();
        setCurrID("three")
        break;
      case "three":
        document.getElementById("two")?.focus();
        setCurrID("two")
        break;
      case "two":
        document.getElementById("one")?.focus();
        setCurrID("one")
        break;
      case "one":
        document.getElementById("one")?.focus();
        break;
      default:
        break;
    }
  }

  return (
    <div className="otp-box">
      <div className="textfield-box textfield-active">
        <input
          id="one"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("two")
            }
          }}
          autoFocus={true}
          // onInput={props.setVal}
          // autoFocus={one1} 
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield first-textfield"
        />
      </div>
      <div className="textfield-box">
        <input id="two"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("three")
            }
          }}
          // onInput={props.setVal}
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input id="three"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("four")
            }
          }}
          // onInput={props.setVal}
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input id="four"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("five")
            }
          }}
          // onInput={props.setVal}
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input id="five"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("six")
            }
          }}
          // onInput={props.setVal}
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input id="six"
          onChange={e => {
            if (e.target.value.length) {
              moveCursor("six")
            }
          }}
          // onInput={props.setVal}
          maxLength={1}
          type="text"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
    </div>
  )
}