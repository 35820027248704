import React, { useState } from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import Dropdown from '../../components/dropdown/dropdown';
import './css/upgrade-account.css'
import UploadInput from '../../components/upload-input/upload-input';
import Toast from '../../components/Toast/toast';
import axios from 'axios';

interface Props {
    returningUser?: boolean,
    closeModal: (data: boolean) => void,
    navigate: () => void
}

export default function UpgradeAccount(props: Props) {
    const {
        closeModal,
        navigate
    } = props;

    const [processing, setProcessing] = useState(false);
    const [nin, setNIN] = useState("");
    const [dob, setDOB] = useState("");

    const verifyBVN = async () => {
        setProcessing(true);
        try {
            const payload = {
                "type": "nin",
                "number": nin
            }
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/identity/verification`, payload);
            if (res.data && res.data.code === 200) {
                Toast(res.data.message, "success");
                localStorage.setItem("identity", nin);
                localStorage.setItem("referenceCode", res.data?.data.referenceCode);
                navigate();
            } else {
                // res.data?.data
                Toast(res.data.message, "error");
            }
            setProcessing(false);

        } catch (error) {
            console.log(error.response)
        }
    }

    return (
        <>
            <div className="upgraact-central-area">
                <div className="text-area">Verify Your Identity (Step 1/2)</div>
                <div style={{ height: 40 }}></div>

                <TextInput labelName="National Identity Number" onInput={(e: any) => setNIN(e.target.value)} />
                <div className="upgraact-text-info-area">
                    {/* <div>Simply dial *565*0# to check your NIN</div> */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-label="bvnTitle" xmlns="http://www.w3.org/2000/svg">
                        {/* <g>
                            <title id="bvnTitle">Your NIN does not give us access to your bank accounts or transactions. We use your NIN to ensure your account belongs to you.</title>
                        </g> */}
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11 6.6C11.5515 6.6 12 7.00365 12 7.5V11.1C12 11.5963 11.5515 12 11 12H5C4.4485 12 4 11.5963 4 11.1V7.5C4 7.00365 4.4485 6.6 5 6.6H5.5V5.25C5.5 4.00935 6.6215 3 8 3C9.3785 3 10.5 4.00935 10.5 5.25V6.6H11ZM8 3.9C7.173 3.9 6.5 4.5057 6.5 5.25V6.6H9.5V5.25C9.5 4.5057 8.827 3.9 8 3.9Z" fill="#333333" />
                    </svg>
                    <div className="bvnTitle">Your NIN does not give us access to your bank accounts or transactions. We use your NIN to ensure your account belongs to you.</div>
                </div>

                <div style={{ height: 40 }} />
                <div className="upgraact-submit-button">
                    <div className="first-btn">
                        <Button btn_name="Cancel" color="btn-default" onClick={() => closeModal(true)} />
                    </div>
                    <div className="second-btn">
                        <Button
                            btn_name="Verify NIN"
                            color="btn-blue"
                            loading={processing}
                            onClick={verifyBVN}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}