import React, { useState } from 'react'
import './css/merchant-profile.css';
import success_logo from '../../components/asset/successful-purchase.svg';
import Card from '../../components/card/card';
import Button from '../../components/button/button';
import MerchantProfileDetails from '../../components/merchant-profile-details/merchant-profile-details';
import MerchantBusinessDetails from '../../components/merchant-business-details/merchant-business-details';
import MerchantPayoutDetails from '../../components/merchant-payout-details/merchant-payout-details';

interface Props {

}

export default function MerchantProfile(props: Props) {
    const [network, setNetwork] = useState("");
    const [account, setAccount] = useState("");
    const [amount, setAmount] = useState("");

    return (
        <>
            <div className="box-core">
                <MerchantProfileDetails />
                <br />
                <MerchantBusinessDetails />
                <br />
                <MerchantPayoutDetails />
            </div>
        </>
    );
}