import React from 'react'
import Button from '../button/button';
import './css/success-card.css'

interface Props {
  title: String,
  message?: JSX.Element,
  btnText?: string,
  transaction_id?: String,
  btnDisabled?: boolean,
  onClick?: () => void,
  closeModal: () => void,

}

export default function SuccessCard(props: Props) {
  const {
    title,
    message,
    btnText,
    transaction_id,
    onClick,
    closeModal,
    btnDisabled,
  } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="success-box">
        <div className="thick-deco">
          <div className="first-circle">
            <div className="second-circle">
              <div className="thick-box">
                <img src={require("../asset/check.svg").default} alt="check-mark" className=" check-mark" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="success-card-body">
          <div className="title">
            {title}
          </div>
          <div className="body-section">
            {message}
          </div>
          {transaction_id && <div className="reference">
            Transaction reference #{transaction_id}
          </div>}
        </div>
        {btnText && <div className="btn-area">
          <Button
            btn_name={btnText}
            color="btn-print"
            icon={<img src={require("../asset/print.svg").default} alt="" />}
            onClick={onClick}
            disabled={btnDisabled}
          />
        </div>}
      </div>

      <div className="modal-close-box" onClick={closeModal}>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V34C0 39.5228 4.47715 44 10 44H34C39.5228 44 44 39.5228 44 34V10C44 4.47715 39.5228 0 34 0H10ZM28.425 16.7583L23.175 22L28.425 27.2417C28.5828 27.3981 28.6715 27.6111 28.6715 27.8333C28.6715 28.0555 28.5828 28.2685 28.425 28.425C28.2685 28.5828 28.0555 28.6715 27.8333 28.6715C27.6111 28.6715 27.3981 28.5828 27.2417 28.425L22 23.175L16.7583 28.425C16.6019 28.5828 16.3889 28.6715 16.1667 28.6715C15.9445 28.6715 15.7315 28.5828 15.575 28.425C15.4172 28.2685 15.3285 28.0555 15.3285 27.8333C15.3285 27.6111 15.4172 27.3981 15.575 27.2417L20.825 22L15.575 16.7583C15.2482 16.4316 15.2482 15.9018 15.575 15.575C15.9018 15.2482 16.4316 15.2482 16.7583 15.575L22 20.825L27.2417 15.575C27.5684 15.2482 28.0982 15.2482 28.425 15.575C28.7518 15.9018 28.7518 16.4316 28.425 16.7583Z" fill="white" />
        </svg>
      </div>
    </div>
  )
}