import React, { useEffect, useRef, useState } from 'react'
import './css/transaction-details.css';
import success_logo from '../../components/asset/successful-purchase.svg';
import Card from '../../components/card/card';
import { useParams } from 'react-router';
import Button from '../../components/button/button';
import Toast from '../../components/Toast/toast';
import {
    fetchTransactions,
    fetchTransactionById,
    UniversalReq
} from '../../actions/auth';
import Loader from '../../components/loader/loader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import GoBack from '../../components/goback/goback';
import Modal2 from '../../components/modal-v2/modal2';
import TransactionReceipt from '../../components/transaction-receipt/transaction-receipt'
import { useReactToPrint } from 'react-to-print';
import TransactionReceiptV2 from '../../components/transaction-receipt-v2/transaction-receipt-v2';

interface Props {
    returningUser?: boolean,
    fetchTransactions: () => void,
    fetchTransactionById: (agentId: string) => void,
    UniversalReq: (payloadObj: any) => void,
    auth: AuthProps,
    users: UserProps
}

interface AuthProps {
    loading: boolean,
    states?: React.SetStateAction<never[]>,
    cities?: React.SetStateAction<never[]>,
    transactions?: React.SetStateAction<never[]>,
    transactions_id?: React.SetStateAction<never[]>,
    universal_request?: React.SetStateAction<never[]>,
}

interface UserProps {
    sa_details?: any,
}

interface TransactionProps {
    account?: string,
    amount: number,
    appname: string,
    bankname: string,
    cardholder: string,
    cardtype: string,
    created_at: string,
    credited_amount: number,
    datetime: string,
    duration: string,
    expirydate: string,
    id: number,
    merchantdetails: string,
    merchantid: string,
    pan: string,
    platform: { id: 3, name: "pos", created_at: "2021-05-03T04:41:21.000000Z", updated_at: "2021-05-03T04:41:21.000000Z" }
    platform_id: number,
    post_balance: number
    pre_balance: number
    ptsp: string,
    responsecode: string,
    reference?: string,
    retrievalreferencenumber: string,
    service: any
    stan: string,
    status?: string,
    terminalid: string,
    transaction_id: string,
    transactiontype: string,
    updated_at: string,
    user_id: number
    website: string,
}

function TransactionDetails(props: Props) {
    const [details, setDetails] = useState<TransactionProps>()
    const {
        UniversalReq,
        auth: {
            loading,
            universal_request,
        },
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const componentRef = useRef();

    const showModal = () => {
        setIsModalVisible(true);
        setTimeout(() => {
            if (handlePrint) {
                handlePrint();
            }
        }, 2000);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current || null,
        onAfterPrint: () => window.location.reload(),
        documentTitle: details?.transaction_id
    });

    const { trans_type, id } = useParams<{ id: "", trans_type: any }>()

    useEffect(() => {
        let uni_req: any = universal_request || {};
        const makeFetch = async () => {
            const payload = {
                "method": "get",
                "url": `${process.env.REACT_APP_BASE_URL}/superagent/transactions/${trans_type}/${id}`
            }
            await UniversalReq(payload)
        };

        if (Object.entries(uni_req).length === 0) {
            makeFetch();
        }

        if (Object.entries(uni_req).length > 0) {
            setDetails(uni_req)
            console.log(uni_req)
        }
    }, [universal_request])

    const month = details && `${new Date(details.created_at).toLocaleString('default', { month: 'long' })}`;

    return (
        <>
            {loading && <>
                <br />
                <Loader className="loader-lg" />
            </>}
            {!loading && details &&
                <div className="box-core">
                    <div className="box-header">
                        <img src={success_logo} />
                    </div>

                    <br />

                    <div style={{ width: "90%" }}>
                        <Card className="box-card-details">
                            <div>Transaction Details</div>
                            <br />
                            <div className="details-main">
                                <div className="space-rule">
                                    <div className="details-title">MONTH</div>
                                    <div className="details-text">
                                        {`${new Date(details.created_at).toLocaleString('default', { month: 'long' })}, ${new Date(details.created_at).getFullYear()}`}
                                    </div>
                                </div>
                                <div className="space-rule">
                                    <div className="details-title">TRANSACTION TYPE</div>
                                    <div className="details-text">{details.account}</div>
                                </div>
                                <div className="space-rule">
                                    <div className="details-title">REFERENCE</div>
                                    <div className="details-text">{details.reference || details?.transaction_id}</div>
                                </div>
                                <div className="space-rule">
                                    <div className="details-title">DATE</div>
                                    <div className="details-text">{moment(details.created_at).format('MMMM d, YYYY')}</div>
                                </div>
                                <div className="space-rule">
                                    <div className="details-title">AMOUNT</div>
                                    <div className="details-text">{`₦${details.amount}`}</div>
                                </div>
                            </div>
                        </Card>

                        <br />

                        <div className="details-sub">
                            <Card className="box-card-details m-space">
                                <div>Service Details</div>
                                <br />
                                <div className="details-sub-inner">
                                    <div>
                                        <div className="details-title">ACCOUNT NUMBER</div>
                                        <div className="details-text">{details.account}</div>
                                    </div>
                                    <div>
                                        <div className="details-title">RECIPIENT</div>
                                        <div className="details-text">1500</div>
                                    </div>
                                    <div>
                                        <div className="details-title">DESCRIPTION</div>
                                        <div className="details-text">Wuse Zone 5, Abuja</div>
                                    </div>
                                </div>
                            </Card>

                            <Card className="box-card-details m-space">
                                <div>CHANNEL</div>
                                <br />
                                <div className="details-sub-inner">
                                    <div>
                                        <div className="details-title">TRANSACTION CHANNEL</div>
                                        <div className="details-text">{details?.credited_amount}</div>
                                    </div>
                                    <div>
                                        <div className="details-title">YOUR IP</div>
                                        <div className="details-text">{details?.post_balance}</div>
                                    </div>
                                    <div>
                                        <div className="details-title">AGENT NAME</div>
                                        <div className="details-text">{details.terminalid}</div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <GoBack customStyles={{ marginTop: 0, paddingRight: 20 }} />
                            <Button
                                btn_name="Print Receipt"
                                color="btn-default"
                                customStyles={{ border: "0.8px solid #1B0E44", backgroundColor: "#fff" }}
                                loading={false}
                                onClick={showModal}
                                icon={<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H11.5C11.8978 0 12.2794 0.158035 12.5607 0.43934C12.842 0.720644 13 1.10218 13 1.5V2H13.5C14.163 2 14.7989 2.26339 15.2678 2.73223C15.7366 3.20107 16 3.83696 16 4.5V9.5C16 9.89782 15.842 10.2794 15.5607 10.5607C15.2794 10.842 14.8978 11 14.5 11H13V12.5C13 12.8978 12.842 13.2794 12.5607 13.5607C12.2794 13.842 11.8978 14 11.5 14H4.5C4.10218 14 3.72064 13.842 3.43934 13.5607C3.15804 13.2794 3 12.8978 3 12.5V11H1.5C1.10218 11 0.720644 10.842 0.43934 10.5607C0.158035 10.2794 0 9.89782 0 9.5V4.5C0 3.83696 0.263392 3.20107 0.732233 2.73223C1.20107 2.26339 1.83696 2 2.5 2H3V1.5ZM12 1.5C12 1.36739 11.9473 1.24021 11.8536 1.14645C11.7598 1.05268 11.6326 1 11.5 1H4.5C4.36739 1 4.24021 1.05268 4.14645 1.14645C4.05268 1.24021 4 1.36739 4 1.5V2H12V1.5ZM4 8.5V12.5C4 12.6326 4.05268 12.7598 4.14645 12.8536C4.24021 12.9473 4.36739 13 4.5 13H11.5C11.6326 13 11.7598 12.9473 11.8536 12.8536C11.9473 12.7598 12 12.6326 12 12.5V8.5C12 8.36739 11.9473 8.24021 11.8536 8.14645C11.7598 8.05268 11.6326 8 11.5 8H4.5C4.36739 8 4.24021 8.05268 4.14645 8.14645C4.05268 8.24021 4 8.36739 4 8.5Z" fill="#1B0E44" />
                                </svg>
                                }
                            />
                        </div>


                        <Modal2
                            isModalVisible={isModalVisible}
                            handleOk={handleOk}
                            handleCancel={handleCancel}
                            showFooter={false}
                        >
                            {trans_type === 'airpay' ? <TransactionReceiptV2
                                amount={details?.amount}
                                created_at={details?.created_at}
                                cardtype={details?.cardtype}
                                cardholder={details?.cardholder}
                                expirydate={details?.expirydate}
                                merchantid={details?.merchantid}
                                ptsp={details?.ptsp}
                                responsecode={details?.responsecode}
                                retrievalreferencenumber={details?.retrievalreferencenumber}
                                stan={details?.stan}
                                terminalid={details?.terminalid}
                                transactiontype={details?.transactiontype}
                                bankname={details?.bankname}
                                pan={details?.pan}
                                ref={componentRef}
                            /> :
                                <TransactionReceipt
                                    date={details?.created_at}
                                    recipient={details?.account || ""}
                                    description={""}
                                    status={details?.status || (details?.responsecode == "00" ? "success" : "failure") || ""}
                                    reference={details?.reference || details?.transaction_id || ""}
                                    ref={componentRef}
                                    merchant={details?.merchantdetails ?? "test"}
                                    amount={details?.amount}
                                />}
                        </Modal2>

                        <br />
                    </div>
                </div>}
        </>
    );
}


function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        users: state.users
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchTransactions,
    fetchTransactionById,
    UniversalReq
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);