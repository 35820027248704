import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Button from '../../components/button/button';
import './css/verify-account.css';
import Card from '../../components/card/card';
import Checkmark from '../../components/checkmark/checkmark';
import ModalDialogbox from '../../components/modal-dialog-box/modal-dialog-box';
import UploadDocument from '../upload-document/upload-document';
import {
    completeRegistration,
    fetchStates,
    fetchCityByStateId
} from '../../actions/auth';
import { fetchUserDetails } from '../../actions/users';
import Loader from '../../components/loader/loader';
import VerifyOptSetting from '../verify-opt-setting/verify-opt-setting';
import UpgradeAccount from '../upgrade-account/upgrade-account';
import UpgradeAccountDoc from '../upgrade-account-doc/upgrade-account-doc';
import BankingDetails from '../banking-details/banking-details';
import axios from 'axios';
import Toast from '../../components/Toast/toast';

interface Props {
    returningUser?: boolean,
    fetchStates: () => void,
    fetchCityByStateId: (id: string) => void,
    fetchUserDetails: () => void,
    auth: AuthProps,
    users: UserProps
}

interface AuthProps {
    loading: boolean,
    states?: React.SetStateAction<never[]>,
    cities?: React.SetStateAction<never[]>,
}

interface UserProps {
    sa_details?: any,
}

function VerifyAccount(props: Props) {
    const [processing, setProcessing] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isState, setIsState] = useState(false);
    const [isCity, setIsCity] = useState(false);
    const [type, setType] = useState("");
    const [userDetails, setUserDetails] = useState({});
    const [allStates, setStates] = useState([]);
    const [allCities, setCities] = useState([]);
    const [fetchingBanks, setFetchingBanks] = useState(false);
    const [banks, setBanks] = useState([]);

    const {
        fetchStates,
        fetchCityByStateId,
        fetchUserDetails,
        auth: {
            loading,
            states,
            cities,
        },
        users: {
            sa_details
        },
    } = props;


    useEffect(() => {
        let sa_has_details = sa_details ? (Object.keys(sa_details).length > 0 ? true : false) : null;
        if (states) {
            setStates(states);
            setIsState(false);
        }
        if (cities) {
            setCities(cities);
            setIsCity(false);
        }
        if (type === "doc" && !allStates.length) {
            fetchStates();
            setIsState(true);
        }
        if (type === "bank" && !banks.length) {
            fetchBanks();
        }
        if (!sa_has_details) {
            fetchUserDetails();
        }
        if (sa_has_details) {
            setUserDetails(prevState => ({
                ...prevState,
                sa_details
            }));
        }
    }, [states, cities, type, sa_details])

    const _fetchCityByStateId = (id: string) => {
        setIsCity(true);
        fetchCityByStateId(id);
    }

    const fetchBanks = async () => {
        try {
            setFetchingBanks(true);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/transfer/banks`);
            if (res && res.data) {
                setBanks(res.data?.data);
            }
            setFetchingBanks(false);
        } catch (error) {
            console.log(error.response)
            Toast(error.response && error.response.data.message, "error");
        }
    }

    const activate = async () => {
        setProcessing(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/activation`);
            if (res.data && res.data.code === 200) {
                Toast(res.data.message, "success");
                setTimeout(() => {
                    window.location.href = "/overview";
                }, 2000);
            } else {
                Toast(res.data.message, "error");
            }
            setProcessing(false);
        } catch (error) {
            Toast(error.response && error.response.data.message, "error");
            setProcessing(false);
        }
    }

    let sa_has_details = sa_details ? (Object.keys(sa_details).length > 0 ? true : false) : null;

    const {
        nin_verified,
        doc_uploaded,
        bank_account_added
    } = sa_details || {};

    return (
        <>
            <div className="verify-box-management">
                <div className="verify-logo-area">
                    <img src={require("../../components/asset/verify-icon.svg").default} alt="" className="login-logo" />
                </div>

                <br />
                {/* <div style={{ height: 36 }} /> */}

                <div className="verify-heading">
                    Submit required documents
                </div>

                <div className="verify-info-area">
                    You’re a few steps away from using Airvend, provide the information needed below and submit your activation request. Your account should be activated before 48hours. You can reach out to the support team via our email channels or the live chat.
                </div>

                <br />
                {/* <Card className="box-card"> */}
                {sa_has_details ? <div className="verify-central-area">

                    <div className="verify-card-component with-border">
                        <div className="left-div">
                            <div className="top-elem">
                                Verify Your Identity
                            </div>
                            <div className="bottom-elem">
                                Upload your Identification documents.
                            </div>
                        </div>
                        <div className="right-div">
                            {nin_verified ? <Checkmark /> : <div style={{ width: "auto" }}>
                                <Button
                                    btn_name="NIN & ID Cards"
                                    color="btn-blue"
                                    onClick={() => {
                                        setVisible(true);
                                        setType("bvn");
                                    }}
                                />
                            </div>}
                        </div>
                    </div>

                    <div className="verify-card-component with-border">
                        <div className="left-div">
                            <div className="top-elem">
                                Upload Business Documents
                            </div>
                            <div className="bottom-elem">
                                Tell us more about your business.
                            </div>
                        </div>
                        <div className="right-div">
                            {doc_uploaded ? <Checkmark /> : <div style={{ width: "auto" }}>
                                <Button
                                    btn_name="Upload Documents"
                                    color="btn-blue"
                                    onClick={() => {
                                        setVisible(true);
                                        setType("doc");
                                    }}
                                />
                            </div>}
                        </div>
                    </div>

                    <div className="verify-card-component with-border">
                        <div className="left-div">
                            <div className="top-elem">
                                Save Banking Details
                            </div>
                            <div className="bottom-elem">
                                Input your banking and payout details.
                            </div>
                        </div>
                        <div className="right-div">
                            {bank_account_added ? <Checkmark /> : <div style={{ width: "auto" }}>
                                <Button
                                    btn_name="Banking Details"
                                    color="btn-blue"
                                    onClick={() => {
                                        setVisible(true);
                                        setType("bank");
                                    }}
                                />
                            </div>}
                        </div>
                    </div>

                    <div className="verify-card-component">
                        <div className="left-div">
                            <div className="top-elem">
                                Submit Activation Request
                            </div>
                            <div className="bottom-elem">
                                Verify your information is correct before proceeding.
                            </div>
                        </div>
                        <div className="right-div">
                            <div style={{ width: "auto" }}>
                                <Button
                                    btn_name="Request for Activation"
                                    color="btn-green"
                                    loading={processing}
                                    onClick={activate}
                                />
                            </div>
                        </div>
                    </div>
                </div> : <Loader className="loader-lg" />}
            </div>

            <ModalDialogbox modalState={visible}>
                {type === "doc" && <UploadDocument
                    states={allStates}
                    cities={allCities}
                    loading={loading}
                    fetchCity={_fetchCityByStateId}
                    isState={isState}
                    isCity={isCity}
                    closeModal={e => setVisible(!e)}
                />}
                {type === "bvn" && <UpgradeAccount
                    closeModal={e => setVisible(!e)}
                    navigate={() => setType("bvn-otp")}
                />}
                {type === "bvn-otp" && <VerifyOptSetting
                    closeModal={e => setVisible(!e)}
                    navigate={() => setType("bvn-doc")}
                />}
                {type === "bvn-doc" && <UpgradeAccountDoc
                    closeModal={e => setVisible(!e)}
                />}
                {type === "bank" && <BankingDetails
                    closeModal={e => setVisible(!e)}
                    loading={fetchingBanks}
                    banks={banks}
                />}
            </ModalDialogbox>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        users: state.users
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    completeRegistration,
    fetchStates,
    fetchCityByStateId,
    fetchUserDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);