import React, { useState } from 'react'
import './css/profileimage.css'
// import image from ''
import imaget from "../asset/imagescopy.jpg"
import Loader from '../loader/loader'


interface Props {
    image?: JSX.Element,
    loading?: boolean,
}
export default function Profileimage(props: Props) {
   const { image, loading } = props

    // const [imagePath, setimagePath] = useState(props.image)
    // '../asset/images(13).jpg'
   
    return (
        <>
            {!props.loading && <img src={props.image || `https://via.placeholder.com/150/FFFFFF/808080?text=Profile Image`} alt="Profile Image" className="profile-image" />}
            {props.loading && <div className="profile-image loader-position">
                <Loader className="loader-lg" />
            </div>
            }
        </>
    )
}
