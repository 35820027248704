import MainLayout from "../pages/main-layout";
import AuthLayout from "../pages/auth-layout/auth-layout";

import Login from "../pages/login/login";
import { ForgotPassword } from "../pages/forgot-password/forgot-password";
import { ResetPassword } from "../pages/reset-password/reset-password";
import Transactions from "../pages/transactions/transactions";
import Transactions_id from "../pages/transactions-id/transactions-id";
import TransactionDetails from "../pages/transaction-details/transaction-details";
import Register from "../pages/register/register";
import BankingDetails from "../pages/banking-details/banking-details";
import VerifyAccount from "../pages/verify-account/verify-account";
import ModalDialogox from "../components/modal-dialog-box/modal-dialog-box";
import UploadDocument from "../pages/upload-document/upload-document";
import Comfirmation from "../components/comfirmation/comfirmation";
import Overview from "../pages/overview/overview";
import Settings from "../pages/settings/settings";
import RegisterProfileDetails from "../pages/register-profile-details/register-profile-details";
import RegisterVerifyPhone from "../pages/register-verify-phone/register-verify-phone";
import Merchants from "../pages/merchants/merchants";
import MerchantsOverview from "../pages/merchants-overview/merchants-overview";
import MerchantProfile from "../pages/merchant-profile/merchant-profile";
import TextInput from "../components/text-input/text-input";
import UpgradeAccount from "../pages/upgrade-account/upgrade-account";
import VerifyOptSetting from "../pages/verify-opt-setting/verify-opt-setting";
import BillPaymentFailed from "../components/bill-payment-failed/bill-payment-failed";
import Otp from "../components/otp/otp";
import Commissions from "../pages/commissions/commissions";
import commissionsEdit from "../pages/commissions-edit/commissions-edit";
import Terms from "../pages/terms-policy/terms";
import Policy from "../pages/terms-policy/policy";
import billPaymentsSend from "../pages/bill-payments-send/bill-payments-send";

const _routes = [
  {
    component: Login,
    route: "/",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Otp,
    route: "/try",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Login,
    route: "/login",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Register,
    route: "/register",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Register,
    route: "/invite/:phone_number",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: BankingDetails,
    route: "/banking-details",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: RegisterProfileDetails,
    route: "/register/profile",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: UploadDocument,
    route: "/try",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: RegisterVerifyPhone,
    route: "/register/verify",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: ForgotPassword,
    route: "/forgot-password",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: ResetPassword,
    route: "/reset-password",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: UploadDocument,
    route: "/upload-documents",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Commissions,
    route: "/commissions",
    layout: MainLayout,
    protected: false,
  },
  {
    component: commissionsEdit,
    route: "/commissions/:id",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Overview,
    route: "/overview",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Transactions,
    route: "/transactions",
    layout: MainLayout,
    protected: false,
  },
  {
    component: billPaymentsSend,
    route: "/bill-payments/send",
    layout: MainLayout,
    protected: false,
  },
  {
    // component: Transactions_id,
    component: Transactions,
    route: "/transactions/:id",
    layout: MainLayout,
    protected: false,
  },
  {
    component: TransactionDetails,
    route: "/transactions/details/:trans_type/:id",
    layout: MainLayout,
    protected: false,
  },
  {
    component: VerifyAccount,
    route: "/verify-account",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Merchants,
    route: "/merchants",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Settings,
    route: "/settings",
    layout: MainLayout,
    protected: false,
  },
  {
    component: MerchantsOverview,
    route: "/merchants/overview/:id",
    layout: MainLayout,
    protected: false,
  },
  {
    component: MerchantProfile,
    route: "/merchants/profile",
    layout: MainLayout,
    protected: false,
  },
  {
    component: Terms,
    route: "/terms",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Policy,
    route: "/policy",
    layout: AuthLayout,
    protected: false,
  },
];

export default _routes;
