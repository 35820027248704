import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../../components/card/card';
import TextInput from '../../components/text-input/text-input';
import Dropdown from '../../components/dropdown/dropdown';
import Button from '../../components/button/button';
import './css/bill-payments-send.css';
import './css/bill-payments-power.css';
import send_money from '../../components/asset/send-money-logo.svg';
import back_arrow from '../../components/asset/bill-payments/back-arrow.svg';
import ModalDialogox from '../../components/modal-dialog-box/modal-dialog-box';
import { fetchBanks } from '../../actions/utils';
import { sendMoney } from '../../actions/vas';
import { Modal } from '@material-ui/core';
import ServiceCard from '../../components/service-card/service-card';
import Loader from '../../components/loader/loader';
import TransactionReceipt from '../../components/transaction-receipt/transaction-receipt';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from '../../api/apiClient';
import Resolver from '../../components/Resolver/resolver';
import GoBack from '../../components/goback/goback';
import SuccessCard from '../../components/success-card';
import Toast from '../../components/Toast/toast';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Modal2 from '../../components/modal-v2/modal2';

interface Props {
    utils: any,
    fetchBanks: () => void,
    sendMoney: (payload: {}) => void,
}

function BillPaymentsSend(props: Props) {
    const [network, setNetwork] = useState("");
    const [account, setAccount] = useState("");
    const [amount, setAmount] = useState("");
    const [code, setCode] = useState("");
    const [narration, setNarration] = useState("");
    const [OpenModal, setOpenModal] = useState(false);
    const [OpenSuccess, setOpenSuccess] = useState(false)
    const [OpenFail, setOpenFail] = useState(false)
    const [Pin, setPin] = useState("")
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [resolving, setResolving] = useState(false);
    const [resolved, setResolved] = useState(false);
    const [showReceipt, setShowReceipt] = useState(false);
    const [resolvedData, setResolvedData] = useState({ account_name: "" });
    const [transactionID, setTransactionID] = useState("");

    const componentRef = useRef();
    const { fetchBanks, sendMoney } = props;



    useEffect(() => {
        fetchBanks()
    }, [])

    useEffect(() => {
        if (props?.utils?.banks?.length > 0) {
            setLoading(false);
        } else {
            // const { fetchBanks } = props;
            fetchBanks();
        }
    }, [props?.utils?.banks])

    useEffect(() => {
        if (account) {
            setResolving(true);
            const delayDebounceFn = setTimeout(() => {
                resolveAccount();
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [account])

    useEffect(() => {
        if (props?.utils?.banks?.length > 0) {
            setLoading(false);
        }
    }, [props?.utils?.banks])

    const handleSubmit = async (pin: string) => {
        setProcessing(true);
        // const { sendMoney } = props;
        const payload = {
            "account": account,
            "settlementBank": code,
            "amount": amount,
            "remark": narration,
            "pin": pin
        }

        const res: any = await sendMoney(payload);

        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            setTransactionID(res.data.data.transaction_id);
        } else {
            Toast(res.data.message, "error");
        }

        setProcessing(false);
    }

    const resolveAccount = async () => {
        try {
            const payload = {
                "account": account,
                "settlementBank": code
            }

            const res = await api.post(`/transfer/verify`, payload);
            console.log(res.data)

            if (res && res.data) {
                setResolvedData(res.data.data);
                setResolved(true);
            }
            setResolving(false);
        } catch (error) {
            console.log(error.response)
            // Toast(error.response && error.response.data.message, "error");
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current || null,
        onAfterPrint: () => window.location.reload(),
        documentTitle: transactionID
    });

    const handleOk = () => {
        setShowReceipt(false);
    };

    return (
        <>
            {loading ? <Loader className="loader-lg" /> : <div className="bill-box-management">
                <div className="box-header">
                    <img src={send_money} />
                    <div style={{ height: 16 }} />
                    <div className="buy-text">Send Money</div>
                </div>

                <div style={{ height: 36 }} />

                <Card className="box-card">
                    <div className="">
                        {/* <div className="text-form-area"> */}
                        <Dropdown
                            label="Select Bank"
                            valueKey="bankCode"
                            displayKey="bankName"
                            data={props.utils.banks}
                            onInput={(e) => setCode(e.target.value)}
                        />
                        <br />
                        <TextInput
                            labelName="Account Number"
                            onInput={(e: any) => setAccount(e.target.value)}
                        />
                        <Resolver
                            resolvedData={resolvedData?.account_name}
                            resolving={resolving}
                        />
                        <br />
                        <TextInput
                            labelName="Input Amount"
                            onInput={(e: any) => setAmount(e.target.value)}
                        />
                        <small className="amt-subtext">You would be charged NGN 2.50 for this transaction</small>
                        <br />
                        <br />
                        <TextInput
                            labelName="Narration (Optional)"
                            onInput={(e: any) => setNarration(e.target.value)}
                        />
                        <br />
                        <br />
                        <div className="submit-button">
                            <Button
                            disabled = {amount === "" || resolvedData?.account_name === "" || resolving}
                                id="myBtn"
                                btn_name="Send Money"
                                color="btn-blue"
                                onClick={() => setModalState(true)}
                            />
                        </div>
                    </div>
                </Card>

                <GoBack customStyles={{ marginTop: 20, paddingRight: 20, cursor: "pointer" }} />
            </div>}

            <Modal
                open={modalState}
                className="modal"
            >
                <>
                    {!transactionID && <ServiceCard
                        amount={amount}
                        processing={processing}
                        closeModal={() => setModalState(false)}
                        handleSubmit={(pin: string) => handleSubmit(pin)}
                        serviceType="send money"
                        account={account}
                        name={resolvedData?.account_name}
                        bank={props?.utils?.banks?.find((e: any) => e.bankCode === code)?.bankName}
                    />}

                    {transactionID && <SuccessCard
                        title={"Transaction Successful!"}
                        message={<div>Money transfer of <b>NGN{amount}</b> to <b>{account}</b> was successful</div>}
                        transaction_id={transactionID}
                        btnText={"Print Receipt"}
                        onClick={() => {
                            setShowReceipt(true);
                            setModalState(false);
                        }}
                        closeModal={() => setModalState(false)}
                    />}
                </>
            </Modal>

            <Modal2
                isModalVisible={showReceipt}
                handleOk={handleOk}
                handleCancel={handleOk}
                showFooter={false}
            >
                <>
                    <TransactionReceipt
                        date={moment(new Date()).format('LLL')}
                        recipient={`${account} - ${props?.utils?.banks?.find((e: any) => e.bankCode === code)?.bankName}` || ""}
                        description={narration}
                        reference={transactionID}
                        ref={componentRef}
                        merchant={resolvedData?.account_name}
                        amount={parseInt(amount)}
                        status={'success'}
                    />
                </>
            </Modal2>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        utils: state.utils,
    }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchBanks,
    sendMoney,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillPaymentsSend);