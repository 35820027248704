import constants from '../constants/ActionTypes';

const initialState = {
    sa_details: {
        bvn_verified: 0,
        doc_uploaded: 0,
        bank_account_added: 0
    }
}

const userReducer = (state = {}, actions) => {
    switch (actions.type) {
        case constants.LOADING:
            return { ...state, loading: true }
        case constants.LOADING_STOP:
            return { ...state, loading: false }
        case constants.REQUEST:
            return { ...state, loading: true }
        case constants.SA_USER_DETAILS:
            return { ...state, sa_details: actions.payload, loading: false }
        default:
            return state;
    }
}

export default userReducer;