import React, { useState } from 'react';
// import Navbar from '../../components/navbar/navbar';
// import { Sidebar } from '../../components/sidebar/sidebar';
import Sidebar from '../../components/sidebar-main';
import SidebarMini from '../../components/sidebar-mini';
import Navbar from '../../components/navbar-main';
import './styles.css';
import Button from '../../components/button/button';

function MainLayout(props) {
    const show_init = (localStorage.getItem("show_init") === "true" || !localStorage.getItem("show_init")) ? true : false;
    const [show, changeShow] = useState(show_init);

    const toggleShow = () => {
        changeShow(prev => {
            const e = prev == true ? false : true;
            localStorage.setItem("show_init", e.toString());
            return e;
        })
    }

    const pathsToAddClass = ["settings", "merchants/overview", "transactions", "commissions"];
    const path1 = window.location.pathname.split("/")[1];
    const dynamicClass = !pathsToAddClass.includes(path1) ? "container mt-20" : "";

    return (
        <div className="d-flex" id="wrapper">
            {show ? <Sidebar /> : <SidebarMini />}
            <div id="page-content-wrapper">
                <Navbar toggle={toggleShow} />
                {/* <br /> */}
                <div className={dynamicClass}>
                    {props.children}
                </div>
            </div>
            <div className="support-area">
                <Button icon={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.486 0 0 4.486 0 10V14.143C0 15.167 0.897 16 2 16H3C3.26522 16 3.51957 15.8946 3.70711 15.7071C3.89464 15.5196 4 15.2652 4 15V9.857C4 9.59178 3.89464 9.33743 3.70711 9.14989C3.51957 8.96236 3.26522 8.857 3 8.857H2.092C2.648 4.987 5.978 2 10 2C14.022 2 17.352 4.987 17.908 8.857H17C16.7348 8.857 16.4804 8.96236 16.2929 9.14989C16.1054 9.33743 16 9.59178 16 9.857V16C16 17.103 15.103 18 14 18H12V17H8V20H14C16.206 20 18 18.206 18 16C19.103 16 20 15.167 20 14.143V10C20 4.486 15.514 0 10 0Z" fill="white" />
                    </svg>
                }
                    btn_name="Support"
                    color="btn-blue"
                />
            </div>
        </div>
    )
}

export default MainLayout;