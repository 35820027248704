import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import './css/register.css'
import { doRegister, addInput } from '../../actions/auth';
import history from '../../config/history';

function Register(props) {
    let navigation = history();

    const [loading, setLoading] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [referrer, setReferrer] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);

    const doRegister = async () => {
        setLoading(true);
        const { doRegister } = props;
        const payload = {
            "email": props?.auth.input_data?.email,
            "password": password,
            "password_confirmation": password,
            "phone_number": props?.auth.input_data?.phone_number
        }

        localStorage.setItem("referrer", referrer);
        await doRegister(payload);
        setLoading(false);
    }

    useEffect(() => {
        const pathname = window.location.pathname.split("/");
        if (pathname.includes("invite")) {
            setReferrer(pathname[2]);
        }
        if (props?.auth?.registered_user && props?.auth?.registered_user?.code == 200) {
            navigation.push("/register/verify");
        }
    }, [props?.auth?.registered_user])

    return (
        <>
            <div className="box-management-reg">
                <div className="leftContent">
                    <div className="text-area-reg">
                        Register
                    </div>

                    <div className="text-info-area-reg">
                        <p>Please ensure to enter accurate information about yourself and your business.</p>
                    </div>

                    {referrer && <div className="text-info-area-reg">
                        <code>Referred by: {referrer}</code>
                    </div>}

                    <br />

                    <div className="input-box-reg">
                        <TextInput
                            value={props?.auth.input_data?.phone_number}
                            onInput={e => props?.addInput({ phone_number: e.target.value })}
                            labelName="Phone Number"
                        />
                        <TextInput
                            value={props?.auth.input_data?.email}
                            onInput={e => props?.addInput({ email: e.target.value })}
                            type="email"
                            labelName="Email Address"
                        />
                        <TextInput
                            value={password}
                            onInput={e => setPassword(e.target.value)}
                            type="password"
                            labelName="Password"
                        />
                    </div>

                    <div className="checkbox-area-reg">
                        <Checkbox
                            onClick={() => setTermsAccepted(!termsAccepted)}
                            label={<div className="check-label-reg">I agree to the <a href="/terms" target="_blank" className="anchor-decor">Terms of Service</a> and <a href="/policy" target="_blank" className="anchor-decor">Privacy Policy</a></div>} />
                    </div>

                    <div className="submit-button">
                        <Button
                            btn_name="Sign up"
                            color="btn-blue"
                            onClick={doRegister}
                            loading={loading}
                            disabled={!termsAccepted}
                        />
                    </div>

                    <div className="bottom-info">
                        <span>Already Registered?</span><Link to="/login" className="anchor-decor">Login here</Link>
                    </div>

                </div>

                <div className="rightContent">
                    <div className="logo-area-reg">
                        <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo" />
                    </div>
                </div>
            </div>
        </>
    );
}

interface RegisterProps {
    register?: boolean,
}

function mapStateToProps(state: RegisterProps, ownProps: Object) {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    doRegister,
    addInput,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register)