import React from 'react';
import './terms-policy.css';

function Terms() {
    return (
        <div className="tp-container">
            <img src={require("../../components/asset/dark-logo.svg").default} style={{ float: 'right', display: 'inline-block', }} alt="" />
            <br />
            <br />
            <h5>WEBSITE TERMS AND CONDITIONS</h5>
            <span>Effective date: June 5, 2020</span>

            <h5>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
                PLATFORM</h5>

            <h5>1. WHAT’S IN THESE TERMS?</h5>

            <p>These terms tell you the rules for using our platform: https://www.airvend.ng and other
            platforms operated by Callphone Limited.</p>
            
            <h5>2. WHO WE ARE</h5>
            <p>
            <a href="https://www.airvend.ng" target="_blank">https://www.airvend.ng</a> is a platform operated by Callphone Limited. We are a bills
            payment platform that makes it easier for users to make payment to their biller and
            accept payments from their customers.
            </p>
            <p>References in this agreement to &quot;Callphone&quot;, &quot;we&quot;, &quot;our&quot; or &quot;us&quot;, are to Callphone
            Limited depending on the context, and references to &quot;you&quot; or &quot;your&quot; are to the persons
            with whom Callphone enters into this agreement. Callphone offers the Services to you,
            the user, conditioned upon your acceptance of all terms, conditions, policies and notices
            stated here.</p>
            
            <p>These Terms of Service apply to all users of the Platform including, without limitation,
            users who are browsers, vendors, customers and contributors of content. By visiting
            our Platform, you agree to be bound by the following terms and conditions
            (“Terms”) including the additional terms and conditions and policies referenced
            herein. By agreeing to these Terms, you represent that you are at least of the age of
            consent in Nigeria. If you do not agree to all the Terms then you may not access the
            Platform or make use of any of our Services.</p>

            <h5>3. DEFINITIONS</h5>
            <p>In these terms, the following definitions shall apply:
            <b>"Content"</b> means any content, writing, images, audiovisual content or other information
            published on the Platform;</p>

            <p><b>"Materials"</b> means any materials, information or documentation that we may provide to
            you in connection with your use of the Products including documentation, data,
            information developed any use and other materials which may assist in your use of the
            Service;</p>

            <p>
            <b>"Parties"</b> means you and Callphone;
            </p>
            <p><b>"Products or Service"</b> means the Platform including all pages, subpages, blogs,
            forums and other connected internet content whatsoever; bill payments; data vending;
            airtime vending; virtual dollar cards; money transfer and any other service that may be
            provided by Callphone;</p>

            <p><b>"Platform"</b> means the website - https://www.airvend.ng and any other platform operated
            by Callphone;</p>
            <p><b>"Third Party Goods/Services"</b> means goods, products and services of the third party
            that may be advertised on the Platform; and</p>
            <p><b>"URL"</b> means Uniform Resource Locator.</p>

            <h5>4. CREATING AN ACCOUNT</h5>
            <p>To create an account users provide MSISDN and basic KYC details(name, date of
            birth). and creates a unique password that enables users to gain access to their
            accounts.</p>

            <h5>5. SAFETY OF ACCOUNT DETAILS</h5>
            <p>5.1 You may be required to register with us to have access to our Service.</p>
            <p>5.2 You will be required to provide certain personal information, which includes but not
            limited to your name, user name, email address and password. The information
            provided must be correct and accurate.</p>
            <p>5.3 Your account details must not be disseminated to anyone and when you discover that
            your information has been compromised, you agree to notify us immediately.</p>
            <p>5.4 If you choose, or you are provided with, a user identification code, password or any
            other piece of information as part of our security procedures, you must treat such
            information as confidential. you must not disclose it to any third party.</p>

            <p>5.5 We have the right to disable any user identification code or password, whether chosen
            by you or allocated by us, at any time, if in our reasonable opinion you have failed to
            comply with any of the provisions of these Terms.</p>

            <p>5.6 You also acknowledge that you are responsible for the security of your personal
            information and that the Callphone does not accept liability for the security of your
            account as you agree to be responsible for maintaining the confidentiality of your
            passwords or other account identifiers which you choose and all activities under your
            account.</p>
            <p>5.7 Callphone reserves the right to terminate your account where you have provided false
            inaccurate or incorrect information.</p>

            <h5>6. INTELLECTUAL PROPERTY</h5>

            <p>6.1 We are the owners or the licensee of all intellectual property rights in our Platform, and
            in the material published on it. Those works are protected by intellectual property laws.
            All such rights are reserved.</p>
            <p>6.2 You are granted a limited license only, subject to the restrictions provided in these
            Terms.</p>
            <p>6.3 You must not use any part of the Content on our Platform for commercial purposes
            without obtaining a licence to do so from us or our licensors.</p>
            <p>6.4 You agree that we retain ownership of all Content included on the Platform
            (text,graphics, video, software, data, page layout, images, and any other information
            capable of being stored in a computer) other than the contents uploaded by users.</p>
            <p>6.5 Nothing on this Platform shall be construed as granting any license or right to use any
            trademark or logo displayed on the Platform without obtaining the prior written consent
            of Callphone.</p>
            <p>6.6 You hereby agree not to reproduce or distribute Callphone&#39;s intellectual property or use
            the intellectual property for any unlawful purpose.</p>

            <h5>7. PRIVACY POLICY</h5>

            <p>We will only use your personal information as set out in our [LINK TO PRIVACY
            POLICY].</p>

            <h5>8. ACCEPTABLE USE</h5>
            <p>8.1 We may provide you with other items in connection with your use of this Platform.</p>
            <p>8.2 We hereby grant you the license to use our Platform for your personal, non-commercial
            use to retrieve, display and view the Content on a computer screen.</p>
            <p>8.3 The license created under these Terms is limited, non-exclusive, non-transferable and
            revocable.</p>
            <p>8.4 You agree that you will not use the Contents or Materials for any other purpose which
            may be contrary to your license to use this Service.</p>
            <p>8.5 Any unauthorized use by you shall terminate the permission or license granted by this
            Platform.</p>
            <h5>Prohibited Use</h5>
            <p>8.6 You agree not to use the Service in the following manner:</p>
            <p>8.6.1 to harass, abuse or threaten others or otherwise violate any person&#39;s legal rights;</p>
            <p>8.6.2 to perpetrate fraud;</p>

            <p>8.6.3 to create or transmit unnecessary spam to any person or URL;</p>
            <p>8.6.4 to post, transmit or cause to be posted or transmitted, any communication or
            solicitation designed to obtain password, account, or private information of other
            users or persons;</p>
            <p>8.6.5 to post copyrighted content which does not belong to you and without obtaining the
            prior consent of the author;</p>
            <p>8.6.6 to use robot, spider, scraper or other automated means to access this Service
            without obtaining the prior consent of the Callphone;</p>
            <p>8.6.7 to engage in or create any unlawful gambling, sweepstakes, or scheme;</p>
            <p>8.6.8 publishing or distributing any obscene or defamatory material;</p>
            <p>8.6.9 using this Service in any way that impacts user access to the Platform ;</p>
            <p>8.6.10 to engage in advertisement or solicit any User to buy or sell products or services
            without obtaining the prior consent of the Callphone;</p>
            <p>8.6.11 disseminating computer viruses or other software;</p>
            <p>8.6.12 violating any intellectual property rights of the Callphone or any third party;</p>
            <p>8.6.13 to use the Platform or any of the Services for illegal spam activities.</p>
            <p>8.7 Additionally, you agree that you will not do as follows:</p>
            <p>8.7.1 interfere or attempt to interfere with the proper working of this Platform; or</p>
            <p>8.7.2 bypass any measures we may use to prevent or restrict access to this Platform ;</p>
            <p>8.7.3 to interfere with or circumvent the security features of this Service;</p>
            <p>8.7.4 to damage, disable, overburden or impair this Service or any other person&#39;s use of
            this Service.</p>
            <p>8.7.5 to use this Service contrary to the applicable laws and regulations or in a way that
            causes, or may cause harm to this Platform, any person or business entity. We
            reserve the right to terminate your use of the Service for violating any of the
            prohibited uses.</p>
            <p>8.7.6 To reverse engineer or permit the reverse engineering or disassemble any code or
            software from or on the Platform or Services.</p>
            <p>8.7.7 violate the security of the Platform or other Services through any unauthorized
            access, circumvention of encryption or other security tools, data mining or
            interference with any host or user or network.</p>

            <h5>9. WARRANTIES</h5>
            <p>9.1 We do not guarantee that our Platform will be secure or free from bugs or viruses.</p>
            <p>9.2 You are responsible for configuring your information technology, computer programmes
            to access our Platform. You should use your own virus protection software.</p>

            <h5>10. SALE OF GOODS/SERVICES</h5>
            <p>10.1 The Platform may offer Products or Services for sale. Callphone undertakes to give
            accurate information about the description of the Products and Services. However,
            Callphone does not guarantee the reliability of any information relating to the Products
            and Services.</p>
            <p>10.2 We reserve the right to refuse to sell the Services provided on the Platform at our sole
            discretion.</p>

            <p>10.3 Note that there is no refund/replacement policy as the Services provided are sold &quot;As it
            is&quot;.</p>

            <h5>11. PAYMENT AND BILLING</h5>
            <p>11.1 If you register for our Service or to purchase any Services offered on this Platform, you
            agree to pay the full price for the Services when the purchase is made.</p>
            <p>11.2 The total price will also include the taxes applicable on the date of purchase.</p>
            <p>11.3 The total price of the Services provided including all applicable taxes is included upon
            the confirmation of the order.</p>
            
            <h5>12. PERFORMANCE OF SERVICES</h5>
            <p>12.1 Upon payment for our Services, we may offer you the opportunity to book a time and
            date for the performance of the Services. Services will be performed within a
            reasonable time.</p>
            <p>12.2 If you have any questions regarding the time and date for the performance, contact us.</p>

            <h5>13. LINKS TO THIRD PARTY PLATFORMS/SERVICES</h5>
            <p>13.1 The Platform may contain links to other platforms. We are not responsible for examining
            or evaluating the content or accuracy, and we do not warrant and will not have any
            liability or responsibility for third-party materials or platforms, or any other products, or
            services of third-parties.</p>
            <p>13.2 We are not liable for any harm or damages related to the purchase or use of goods,
            services, resources, content, or any other transactions made in connection with any
            third-party platform. Please review the third-party’s policies and practices carefully and
            make sure you understand them before you engage in any transaction. Complaints,
            claims, concerns, or questions regarding third-party products should be directed to the
            third-party.</p>

            <h5>14. ELECTRONIC COMMUNICATIONS</h5>
            <p>You consent to receive electronic communications and you agree that all agreements,
            notices, disclosures and other communications we provide to you electronically, via
            email and on this Platform, satisfy any legal requirements that communications must be
            in writing.</p>
            <h5>15. AFFILIATE MARKETING AND ADVERTISEMENT</h5>

            <p>We may engage in affiliate marketing and advertisement whereby we receive a
            commission on the sale of Third-Party Goods and/or Services through our Service. We
            may also accept advertising and sponsorship from commercial businesses or receive
            other forms of advertising compensation.</p>

            <h5>16. CHANGE TO SERVICE</h5>
            <p>You accept that the Callphone may vary, alter, amend or update the Content or
            Services, at any time and without your consent.</p>

            <h5>17. INDEMNIFICATION</h5>
            <p>You hereby agree to indemnify Callphone, its employees, agents and third parties from
            and against all liabilities, cost, demands, cause of action, damages and expenses
            (including reasonable attorney&#39;s fees) arising out of your unlawful use of the Platform,
            your violation of any rights of a third party and your violation of applicable laws, rules or
            regulation.</p>
            
            <h5>18. NO WARRANTIES</h5>
            <p>18.1 You agree that you use this Platform solely at your risk as Callphone does not warrant
            the accuracy of the contents in this Platform. You assume all the risk of viewing,
            reading, downloading the contents of this Platform.</p>
            <p>18.2 Callphone expressly disclaims all express and implied warranties such as implied
            warranty of merchantability as Callphone makes no warranties that the Platform or other
            Services will be accurate, error-free, secure or uninterrupted.</p>
            <p>18.3 Callphone makes no warranty about the suitability, reliability, availability, timeliness and
            accuracy of the information, Contents, Services and other materials contained herein for
            any purpose. Callphone hereby disclaims all warranties and conditions with regard to
            the information, software, products and related graphics and materials, including all
            implied warranties or conditions of merchantability, fitness for a particular purpose, title
            and non-infringement.</p>
            <p>18.4 You also agree that the Callphone and its affiliates shall not be liable for any direct,
            indirect, punitive and all consequential damages or any damages whatsoever including,
            but not limited to damages for loss of use, data or profits, the failure to provide Services
            or for any information, software, Products, Services, related graphics and materials
            obtained through this Platform, or otherwise arising out of the use of this Platform,
            whether based on contract, negligence, strict liability, or otherwise.</p>

            <h5>19. SERVICE INTERRUPTIONS</h5>
            <p>Callphone may from time to time interrupt your access or use of this Platform including
            to perform some maintenance or emergency services and you agree that the Callphone
            shall not be held liable for any damage, loss which may arise thereof.</p>

            <h5>20. SUSPENSION OR WITHDRAWAL OF PLATFORM</h5>
            <p>20.1 Our Platform is made available free of charge. We do not guarantee that our Platform,
            or any content on it, will always be available or be uninterrupted. We may suspend or
            withdraw or restrict the availability of all or any part of our Platform for business and
            operational reasons. We will try to give you reasonable notice of any suspension or
            withdrawal.</p>
            <p>20.2 You are also responsible for ensuring that all persons who access our Platform through
            your internet connection are aware of these terms of use and other applicable terms
            and conditions and that they comply with them.</p>

            <h5>21. TERMINATION/RESTRICTION OF ACCESS</h5>
            <p>21.1 Callphone reserves the right to, at its sole discretion, terminate your access to this
            Platform and the related Service or any part thereof at any time, for any reason and
            without notice.</p>
            <p>21.2 Callphone shall have the right to terminate or suspend your account for violating these
            Terms.</p>
            <p>21.3 If you register with us, you may terminate the Service at any time by issuing a prior
            notice to us. Once this is done, you will no longer be bound by the provisions of this
            Terms.</p>

            <h5>22. GENERAL PROVISIONS</h5>
            <p>22.1 Assignment: Callphone shall be permitted to assign, transfer its rights and/or
            obligations under these Terms. However, you shall not be permitted to assign, transfer
            any rights and/or obligations under these Terms.</p>
            <p>22.2 Entire Agreement: These Terms, disclaimers and any other agreement relating to the
            use of this Platform constitutes the entire agreement and shall supersede any other
            agreement in relation to the use of the Platform.</p>
            <p>22.3 Separate Agreements: you may have other legal agreements with us. Those
            agreements are separate from these Terms. These Terms are not intended to alter,
            amend, revise or replace the terms of the other agreement(s).</p>
            <p>22.4 Applicable law: These Terms may be governed and construed in accordance with the
            Laws of the Federal Republic of Nigeria.</p>
            <p>22.5 Variation: Callphone may revise these Terms at any time as it sees fit, and by using
            this Platform, you undertake that you shall review the content of the revised Terms
            before accepting the same.</p>
            <p>22.6 Waiver: Failure to exercise any right in these Terms shall not operate as a waiver. The
            right or remedies herein provided are cumulative and not exclusive of any right or
            remedies provided by law.</p>

            <p>22.7 Severability: Every provision contained herein is intended to be severable. If any
            provision is invalid for any reason whatsoever, such invalidity shall not affect the validity
            of other clauses of these Terms.</p>
            
            <h5>23. CONTACT INFORMATION</h5>
           <p>Questions about these Terms should be sent to us at: [info@callphoneng.com]</p>
        </div>
    );
}

export default Terms;