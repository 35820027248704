import React, { useState } from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import Dropdown from '../../components/dropdown/dropdown';
import './css/verify-opt-setting.css'
import UploadInput from '../../components/upload-input/upload-input';
import Otp from '../../components/otp/otp';
import Toast from '../../components/Toast/toast';
import axios from 'axios';

interface Props {
    returningUser?: boolean,
    closeModal: (data: boolean) => void,
    navigate: () => void
}

export default function VerifyOptSetting(props: Props) {
    const {
        closeModal,
        navigate
    } = props;

    const [processing, setProcessing] = useState(false);
    const [otp, setOTP] = useState("");

    const verifyBVN = async () => {
        setProcessing(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/identity/validate`,
                {
                    type: "nin",
                    referenceCode: localStorage.getItem("referenceCode"),
                    number: localStorage.getItem("identity"),
                    otp
                });
            if (res.data && res.data.code === 200) {
                Toast(res.data.message, "success");
                navigate();
            } else {
                // res.data?.data
                // Toast(res.data.message, "error");
                Object.values(res.data?.data)?.map((e: any) => Toast(e[0], "error"))
            }
            setProcessing(false);

        } catch (error) {
            console.log(error.response)
            Toast(error.response.data.message, "error");
            setProcessing(false);
        }
    }

    return (
        <>
            {/* upgraact <div className="box-management"> */}
            {/* <div className="logo-area">
                    <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo"/>
                </div> */}
            <div className="upgraact-central-area">
                <div className="text-area">
                    Verify BVN
                    </div>
                <div style={{ height: 40 }}></div>


                <div className="modal-info">
                    We just sent a six(6) digits one time password to the phone number registered with your BVN. Enter the otp below to verify that you own that phone number.
                    </div>

                <div style={{ height: 40 }}></div>
                {/* <div className="text-success">{localStorage.getItem("bvn-otp")}</div>
                <br/> */}

                <Otp setVal={(e: any) => setOTP(e)} />

                <div style={{ height: 40 }}></div>

                {/* <TextInput labelName="Select Bank" /> */}


                <div className="opt-resend">
                    <div className="green-resend">
                        <Button color="btn-green small-button" btn_name="Resend OTP" />
                    </div>
                    <div className="ash-resend">
                        <Button color="btn-default small-button" btn_name="Resend OTP (60)" />
                    </div>
                </div>


                <div style={{ height: 8 }} />
                {/* <UploadInput labelName="Select .PDF or .JPG Files" appearance="dotted-fade" /> */}

                <div style={{ height: 40 }} />
                <div className="upgraact-submit-button">
                    <div className="first-btn">
                        <Button btn_name="Cancel" color="btn-default" onClick={() => closeModal(true)} />
                    </div>
                    <div className="second-btn">
                        <Button
                            btn_name="Verify Number"
                            color="btn-blue"
                            loading={processing}
                            onClick={verifyBVN}
                        />
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}



