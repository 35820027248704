'use strict';

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import { Provider } from 'react-redux';
import store from '../store/createStore';
import history from '../config/history';
import jwt_decode from "jwt-decode";

import routes from './routes';
import AuthLayout from '../pages/auth-layout/auth-layout';

const AppRoute = ({ Layout, Component, path }, ...rest) => (
    <Route {...rest} path={path} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)

function isAuthenticated() {
    const token = localStorage.getItem("TOKEN");
    if (!token) return false;

    var decoded = jwt_decode(token);

    //  Check for expired token
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        return false;
    }
    return true;
}

const ProtectedRoute = ({ Layout, Component, path }, ...rest) => (
    <Route {...rest} path={path} render={props => (
        isAuthenticated() === true
            ? <Layout>
                <Component {...props} />
            </Layout>
            : <Redirect to={{
                pathname: "/login",
                state: { from: props.location }
            }} />
    )} />
)

const App = () => (
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                {routes.map((e, i) => {
                    if (e.protected) {
                        return <ProtectedRoute key={i} exact path={e.route} Layout={e.layout} Component={e.component} />
                    } else {
                        return <AppRoute key={i} exact path={e.route} Layout={e.layout} Component={e.component} />
                    }
                })}

            </Switch>
        </Router>
    </Provider>
)

export default App;
