import React from 'react'
import './css/checkbox.css'

// export default function Checkbox() {
interface Props {
    returningUser?: boolean,
    label?: JSX.Element,
    onClick?: () => void,
}

export default function Checkbox(props: Props) {
    return (
        <div className="check-box" onClick={props?.onClick}>
            <input type="checkbox" name="" id="check-box-id" />
            <label htmlFor="check-box-id" className="check-label">{props.label ? props.label : "Remember me"}</label>
        </div>
    )
}
