import React, { useEffect, useState } from 'react'
import Button from '../../components/button/button';
import './css/commissions.css';
import Table from '../../components/table/table';
import ModalDialogox from '../../components/modal-dialog-box/modal-dialog-box';
import Dropdown from '../../components/dropdown/dropdown';
import Toast from '../../components/Toast/toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchTransactions,
    fetchTransactionById
} from '../../actions/transactions';


import {
    fetchAgents,
    UniversalReq,
    fetchBaseCommissions,
} from '../../actions/auth';
import Loader from '../../components/loader/loader';
import SettingsNav from '../../components/setting-nav/setting-nav';
import { useParams } from 'react-router';
import { Card } from 'antd';
import Label from '../../components/label/label';

const settingNavContent = [
    {
        "state": "settings-active",
        "name": "Merchant Commissions",
        "path": "/settings-Profile Details"
    },
    {
        "state": "settings-inactive",
        "name": "My Commissions",
        "path": "/settings-Business Details"
    }
]


// interface TransactionProps {
//     loading: boolean,
//     transactions?: React.SetStateAction<never[]>,
//     transactions_id?: React.SetStateAction<never[]>,
// }

interface UserProps {
    sa_details?: any,
}

interface Props {
    returningUser?: boolean,
    fetchTransactions: () => void,
    fetchAgents: () => void,
    fetchBaseCommissions: () => void,
    fetchTransactionById: (agentId: string) => void,
    users: UserProps,
    auth: AuthProps,
}

interface AuthProps {
    loading: boolean,
    states?: React.SetStateAction<never[]>,
    cities?: React.SetStateAction<never[]>,
    transactions?: React.SetStateAction<never[]>,
    transactions_id?: React.SetStateAction<never[]>,
    agents?: React.SetStateAction<never[]>,
    base_commissions?: React.SetStateAction<never[]>,
    universal_request?: React.SetStateAction<never[]>,
}



interface MCProps {
    agents: any[]
}

function MerchantCommissions(props: MCProps) {
    return (
        <>
            <Table
                headerRows={["FullName", "Commissions", "Merchant ID", ""]}
                columnData={props.agents.map((elem, index) => {
                    return {
                        "fullname": elem["email"],
                        "commission": elem["phone_number"],
                        "merchantId": elem["account_id"],
                        "action": <Label
                            name="Edit Commissions"
                            className="label-green"
                        />,
                        "id": elem["id"],
                    }
                })}
                onClick={(e) => {
                    console.log("event : ", e)
                    window.location.href = `/commissions/${e.id}`
                }}
            />

        </>
    )
}

interface MyCProps {
    myCommissions: any[]
}

function MyCommissions(props: MyCProps) {
    const { myCommissions } = props;

    return (
        <>
            <Table
                headerRows={["Bill Categories", "Service Provider", "My Commission", "Date Assigned"]}
                columnData={myCommissions.map((elem, index) => {
                    return {
                        "billCategory": elem["type"],
                        "serviceProvider": elem["service_id"],
                        "myCommission": `${elem["super_agent_comm"]}%`,
                        "dateAssigned": `${new Date(elem.created_at).getMonth()}/${new Date(elem.created_at).getDate()}/${new Date(elem.created_at).getFullYear()}`
                    }
                })}
                // onClick={() => window.location.href = "/transactions/details"}
                onClick={(e) => {
                    console.log(e)
                    // window.location.href = `/transactions/details/airpay/${transaction.filter((detail) => detail["reference_code"] == e.reference)[0]["transaction_id"]}`
                }}
            />

        </>
    )
}


function Commissions(props: Props) {
    const {
        fetchTransactions,
        fetchAgents,
        fetchTransactionById,
        fetchBaseCommissions,
        auth: {
            loading,
            transactions,
            agents,
            universal_request,
            base_commissions
        },
    } = props;

    const [pagenumber, setPagenumber] = useState(() => 0)
    const { id } = useParams() || false;

    useEffect(() => {
        if (id) {
            const fetchWait = async () => {
                await fetchTransactionById(id)
            }
            fetchWait()
        } else {
            const fetchWait = async () => {
                // await fetchTransactions()
                await fetchAgents()
                await fetchBaseCommissions({})
                // if(!agents)fetchWait()
            }
            fetchWait()
        }
    }, [])

    useEffect(() => {
        if(agents?.length < 1 && loading==false){
            const fetchWait = async () => {
                await fetchAgents()
            }
            fetchWait()
        }

        if(base_commissions?.length < 1 && loading==false){
            const fetchWait = async () => {
                await fetchBaseCommissions({})
            }
            fetchWait()
        }
    }, [loading])

    return (
        <>
            <div className="box-management-main">

                <SettingsNav navContent={settingNavContent} handleClick={index => {
                    setPagenumber(prev => index)
                }} />

                <div className="transaction-pseudo-body">
                    
                    <div className="filter-card">
                        <div className="trans-first">
                            <div className="trans-first-inner">
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.4 1.56686C13.3999 1.29789 13.2955 1.03946 13.1087 0.845879C12.922 0.652294 12.6675 0.53863 12.3988 0.528775V0.526855H1.69571V0.529736C1.67715 0.528776 1.65875 0.526855 1.64003 0.526855C1.42495 0.526996 1.2152 0.593818 1.03967 0.718119C0.864142 0.842421 0.73147 1.01809 0.659928 1.22092C0.588385 1.42376 0.58149 1.64379 0.640193 1.8507C0.698896 2.05762 0.820309 2.24125 0.98771 2.3763L5.81011 7.19854L5.80995 10.1744V10.199H5.81235C5.81639 10.2908 5.84365 10.38 5.89159 10.4584C5.93953 10.5367 6.00657 10.6016 6.08643 10.647L6.08499 10.6496L7.34451 11.3768C7.35507 11.3841 7.36675 11.3894 7.37779 11.396L7.38227 11.3985V11.3984C7.46259 11.4448 7.55475 11.4734 7.65427 11.4734C7.72605 11.4734 7.79713 11.4593 7.86345 11.4319C7.92978 11.4044 7.99004 11.3641 8.04079 11.3134C8.09155 11.2626 8.13181 11.2024 8.15927 11.136C8.18673 11.0697 8.20085 10.9986 8.20083 10.9269C8.20083 10.9125 8.19763 10.8989 8.19667 10.8848H8.20083V7.20302L13.0676 2.33646L13.0628 2.33166C13.169 2.23461 13.2539 2.11648 13.312 1.9848C13.3701 1.85312 13.4 1.71077 13.4 1.56686ZM4.15987 2.60686H4.16211L4.16227 2.60926L4.15987 2.60686Z" fill="#4650E5" />
                                </svg>
                                <div id="myBtn" className="filter-text">Filtered By : Type</div>
                            </div>
                        </div>
                        <div className="trans-second">
                            <div className="search-input-main">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.70711 16.7071C10.0976 16.3166 10.0976 15.6834 9.70711 15.2929C9.31658 14.9024 8.68342 14.9024 8.29289 15.2929L4.29289 19.2929C3.90237 19.6834 3.90237 20.3166 4.29289 20.7071C4.68342 21.0976 5.31658 21.0976 5.70711 20.7071L9.70711 16.7071Z" fill="black" />
                                    <path d="M13 16C10.2386 16 8 13.7614 8 11C8 8.23858 10.2386 6 13 6C15.7614 6 18 8.23858 18 11C18 13.7614 15.7614 16 13 16ZM13 18C16.866 18 20 14.866 20 11C20 7.13401 16.866 4 13 4C9.13401 4 6 7.13401 6 11C6 14.866 9.13401 18 13 18Z" fill="black" />
                                </svg>

                                <input className="search-input" type="text" placeholder="Search..." />
                            </div>
                            <Button btn_name="Export CSV" color="btn-export" />
                        </div>
                    </div>

                    <br />

                    {loading && <Loader className="loader-lg" />}
                    {!loading && 

                    <>
                        {/* {pagenumber == 0 && transactions && <Vas transaction={transactions.vas || []} />} */}
                        {pagenumber == 0 && agents && <MerchantCommissions agents={agents || []} />}
                        {/* {pagenumber == 1 && transactions && <AirPay transaction={transactions.airpay || []} />} */}
                        {pagenumber == 1 && base_commissions && <MyCommissions myCommissions={base_commissions || []} />}
                    </>
                    }

                </div>

            </div>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        users: state.users,
        // transactions: state.transactions,
        // agents: state.agents,
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchTransactions,
    fetchAgents,
    fetchBaseCommissions,
    fetchTransactionById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Commissions);