import React from 'react';

interface Props {

}

function Checkmark(props: Props) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM9.11088 4.69649C11.7421 2.93838 14.8355 2 18 2C22.2435 2 26.3131 3.68571 29.3137 6.68629C32.3143 9.68687 34 13.7565 34 18C34 21.1645 33.0616 24.2579 31.3035 26.8891C29.5454 29.5203 27.0466 31.5711 24.1229 32.7821C21.1993 33.9931 17.9823 34.3099 14.8786 33.6926C11.7749 33.0752 8.92394 31.5513 6.6863 29.3137C4.44866 27.0761 2.92481 24.2251 2.30744 21.1214C1.69008 18.0177 2.00693 14.8007 3.21793 11.8771C4.42894 8.95345 6.4797 6.45459 9.11088 4.69649ZM15.31 25.76L28.45 12.63L28.47 12.65C28.6013 12.5187 28.7055 12.3628 28.7766 12.1912C28.8476 12.0196 28.8842 11.8357 28.8842 11.65C28.8842 11.4643 28.8476 11.2804 28.7766 11.1088C28.7055 10.9372 28.6013 10.7813 28.47 10.65C28.3387 10.5187 28.1828 10.4145 28.0112 10.3434C27.8396 10.2724 27.6557 10.2358 27.47 10.2358C27.2843 10.2358 27.1004 10.2724 26.9288 10.3434C26.7572 10.4145 26.6013 10.5187 26.47 10.65L15.33 21.78L9.55001 16C9.28479 15.7348 8.92508 15.5858 8.55001 15.5858C8.17493 15.5858 7.81522 15.7348 7.55001 16C7.28479 16.2652 7.13579 16.6249 7.13579 17C7.13579 17.3751 7.28479 17.7348 7.55001 18L15.31 25.76Z" fill="#6AB04C" />
        </svg>
    )
}

export default Checkmark;