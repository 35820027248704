import moment from 'moment';
import React from 'react';
import './css/transaction-receipt-v2.css';

interface Props {
    amount: number,
    created_at: string,
    cardtype: string,
    cardholder: string,
    expirydate: string,
    merchantid: string,
    ptsp: string,
    responsecode: string,
    retrievalreferencenumber: string,
    stan: string,
    terminalid: string,
    transactiontype: string,
    bankname: string,
    pan: string,
}

const TransactionReceiptV2 = React.forwardRef((props: Props, ref: any) => {
    const {
        amount,
        created_at,
        merchantid,
        cardtype,
        cardholder,
        expirydate,
        ptsp,
        responsecode,
        retrievalreferencenumber,
        stan,
        terminalid,
        transactiontype,
        bankname,
        pan,
    } = props;

    return (
        <div className="receipt-container" ref={ref}>
            <div className="receipt-header">
                <div className="receipt-logo">
                    <img src="https://www.fintechfutures.com/files/2017/09/Providus-Bank-logo.png"
                        alt="Airvend logo" />
                </div>
                <div className="title_1">Purchase</div>
                <div className="title_2">{`Transaction ${responsecode === '00' ? 'Approved' : 'Declined'}`}</div>

                <div className="title_4">
                    <span>-----------</span>
                    Merchant's Copy
                    <span>-----------</span>
                </div>
            </div>

            <section className="body-section">
                <div className="brand_title">Callphone Limited</div>
                <div className="receipt-transaction-details">
                    <div>
                        <span>Merchant ID:</span>
                        <span>{merchantid}</span>
                    </div>
                    <div>
                        <span>TID:</span>
                        <span>{terminalid}</span>
                    </div>
                    <div>
                        <span>STAMP:</span>
                        <span>{moment(created_at).format("LLL")}</span>
                    </div>
                    <div>
                        <span>Card:</span>
                        <span>{pan}</span>
                    </div>
                    <div>
                        <span>Stan:</span>
                        <span>{stan}</span>
                    </div>
                    <div>
                        <span>Cardholder:</span>
                        <span>{cardholder}</span>
                    </div>
                    <div>
                        <span>Exp:</span>
                        <span>{expirydate}</span>
                    </div>
                    <div>
                        <span>CARDTYPE:</span>
                        <span>{cardtype}</span>
                    </div>
                    <div>
                        <span>Acquirer:</span>
                        <span>{bankname.toUpperCase()}</span>
                    </div>
                    <div>
                        <span>Ptsp:</span>
                        <span>{ptsp.toUpperCase()}</span>
                    </div>
                </div>
                <div className="total">
                    <span>***********************************</span>
                    <div>{`NGN${amount}`}</div>
                    <span>***********************************</span>
                </div>

                <div className="receipt-transaction-details">
                    <div>
                        <span>Verification Mode:</span>
                        <span>{transactiontype.toUpperCase()}</span>
                    </div>

                    <div>
                        <span>RRN:</span>
                        <span>{retrievalreferencenumber}</span>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div>Please retain your receipt</div>
                <div>support@airvend.ng <span>|</span> 09038864341</div>
            </footer>
        </div>
    );
})

export default TransactionReceiptV2;