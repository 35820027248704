import constants from '../constants/ActionTypes';

const authReducer = (state = { input_data: {} }, actions) => {
    switch (actions.type) {
        case constants.LOADING:
            return { ...state, loading: true }
        case constants.LOADING_STOP:
            return { ...state, loading: false }
        case constants.REQUEST:
            return { ...state, }
        case constants.INPUT_DATA:
            return { ...state, input_data: Object.assign(state.input_data, actions.payload) }
        case constants.LOGIN_REQUEST:
            return { ...state, data: actions.payload }
        case constants.LOGIN_SUCCESS:
            return { ...state, data: actions.payload, loading: false }
        case constants.LOGIN_FAILURE:
            return { ...state, data: actions.payload, loading: false }
        case constants.REGISTER:
            return { ...state, data: actions.payload }
        case constants.REGISTER_SUCCESS:
            return { ...state, registered_user: actions.payload, phone_number: actions.phone_number, loading: false }
        case constants.REGISTER_FAILURE:
            return { ...state, data: actions.payload, loading: false }
        case constants.LIST_STATES:
            return { ...state, states: actions.payload, loading: false }
        case constants.LIST_CITY_BY_STATE_LOADING:
            return { ...state, loadingCities: true }
        case constants.LIST_CITY_BY_STATE:
            return { ...state, cities: actions.payload, loadingCities: false }
        case constants.LIST_TRANSACTIONS:
            return { ...state, transactions: actions.payload, loading: false }
        case constants.LIST_AGENTS:
            return { ...state, agents: actions.payload, loading: false }
        case constants.LIST_BASE_COMMISSIONS:
            return { ...state, base_commissions: actions.payload, loading: false }
        case constants.LIST_TRANSACTIONS_ID:
            return { ...state, transactions: actions.payload, loading: false }
        case constants.UNIVERSAL_REQUEST:
            return { ...state, universal_request: actions.payload, loading: false }
        case constants.RESET_AUTH:
            return { ...state, registered_user: null }
        default:
            return state;
    }
}

export default authReducer;