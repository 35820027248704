import React, { useState } from 'react'
// import useState from 'react'
import './css/upload-input.css'

interface Props {
  id: string,
  labelName: string,
  appearance?: string,
  type: string,
  accept?: string,
  // onInput?: any,
  getFile: (file: any) => void
}


export default function UploadInput(props: Props) {

  const [labelState, changeLabelState] = useState(props.labelName);

  const showLabel = () => {
    // console.log("show label")
    changeLabelState("select-item-name-show")
  }

  const handleChange = (e: any) => {
    const _label = e.target.files[0].name;
    const _file = e.target.files[0];
    props.getFile(_file);
    changeLabelState(_label || "");
  }

  return (
    <>
      <label
        className={`upload-selected ${props.appearance ? props.appearance : ""}`}
        htmlFor={props.id}>
        {labelState.length > 40 ? `${labelState.slice(0, 40)}...` : (labelState || props.labelName)}
      </label>
      <input id={props.id} style={{ display: "none" }} onChange={handleChange} type={props.type} accept={props.accept || ""} />
    </>
  )
}
