import React, { useState } from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import './css/forgot-password.css'
import Card from '../../components/card/card';
import { Link } from 'react-router-dom';
import api from '../../api/apiClient';
import Toast from '../../components/Toast/toast';
import {useHistory} from 'react-router';

interface Props {
    returningUser?: boolean
}

export const ForgotPassword: React.FC<Props> = ({ returningUser }) => {
    const [phone_number, setPhone] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleSubmit = async () => {
        setLoading(true);
        const res = await api.post(`/auth/forgot/password`, { phone_number });
        if (res.data && res.data.code == 200) {
            localStorage.setItem('reset-password-id', res.data.data.id);
            Toast(res.data.message, "success");
            history.push('/reset-password');
        } else {
            Toast(res.data.message, "error");
        }
        setLoading(false);
    }

    return (
        <>
            <div className="box-management box-bg">
                <div className="logo-area-login">
                    <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo" />
                </div>

                <div style={{ height: 36 }} />

                <Card className="box-card">
                    <div className="central-area">
                        <div className="key-area">
                            <img src={require("../../components/asset/key1.svg").default} alt="key" className="key-icon" />
                        </div>
                        <div className="text-info-area-for">
                            <p>We would send an email to you to help you reset and get back into your account in no time</p>
                        </div>
                        <div className="email-sub-area">
                            <TextInput
                                type="text"
                                labelName="Phone Number"
                                onInput={(e: any) => setPhone(e.target.value)}
                            />

                            <br />
                        </div>
                        <div className="submit-button">
                            <Button
                                btn_name="Continue"
                                color="btn-green"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                        </div>
                    </div>
                </Card>
                <div className="information-elements">
                    <div className="create">
                        <span>Not Registered?</span><Link to="/register">Create Account</Link>
                    </div>
                    <div className="create">
                        <span>Already Registered?</span><Link to="/login">Login here</Link>
                    </div>
                </div>
            </div>
        </>
    );
}



