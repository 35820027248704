import React, { useState } from 'react'
import './css/merchant-payout-details.css';
import success_logo from '../../components/asset/successful-purchase.svg';
import Card from '../card/card';
import Button from '../button/button';

interface Props {

}

export default function MerchantPayoutDetails(props: Props) {
    const [network, setNetwork] = useState("");
    const [account, setAccount] = useState("");
    const [amount, setAmount] = useState("");

    return (
        <>
            <div className="box-core">
                <Card className="box-card-details merchant-card">
                    <div>Payout Details</div>
                    <br />
                    <div className="details-sub-inner merchant-profile-inner">
                        <div>
                            <div>
                                <div className="details-title">BANK NAME</div>
                                <div className="details-text">FIRST BANK NIGERIA</div>
                            </div>
                            <br />
                            <div>
                                <div className="details-title">ACCOUNT NUMBER</div>
                                <div className="details-text">0123456789</div>
                            </div>
                            <br />
                            <div>
                                <div className="details-title">ACCOUNT NAME</div>
                                <div className="details-text">KENE OHIAERI</div>
                            </div>
                        </div>

                        <div>

                        </div>
                    </div>

                    <br />

                    <Button
                        btn_name="View ID Document"
                        color="btn-default"
                        loading={false}
                        icon={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.79167 13.4583C10.9213 13.4583 13.4583 10.9213 13.4583 7.79167C13.4583 4.66205 10.9213 2.125 7.79167 2.125C4.66205 2.125 2.125 4.66205 2.125 7.79167C2.125 10.9213 4.66205 13.4583 7.79167 13.4583Z" stroke="#1B0E44" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.875 14.875L11.7938 11.7937" stroke="#1B0E44" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.79166 5.66663V9.91663" stroke="#1B0E44" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.66666 7.79163H9.91666" stroke="#1B0E44" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    />
                </Card>
            </div>
        </>
    );
}