import React from 'react';
import './styles.css';
// import NavBar from '../../components/NavBar';

interface Props {
    children: any
}

function AuthLayout(props: Props) {
    const pathname = window.location.pathname.split("/")[1];

    return (
        <div>
            {/* <NavBar pathname={pathname} /> */}
            {props.children}
        </div>
    )
}

export default AuthLayout;