import {
    createStore,
    combineReducers,
    compose,
    applyMiddleware
} from 'redux'
import thunkMiddleware from "redux-thunk";
import jwt_decode from "jwt-decode";
import sessionInjector from '../middlewares/sessionInjector';
import appReducer from '../reducers';

function saveToLocalStorage(state) {
    try {
        const stringifiedState = JSON.stringify(state)
        localStorage.setItem("state", stringifiedState)
    } catch (e) {
        console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const stringifiedState = localStorage.getItem('state')
        if (stringifiedState === null) return undefined
        return JSON.parse(stringifiedState)
    } catch (e) {
        console(e)
        return undefined
    }
}

function rootReducer(state, action) {
    return appReducer(state, action)
}

var decoded = {};
const token = localStorage.getItem("TOKEN");

if (token) {
    decoded = jwt_decode(token);
}

const persistedState = loadFromLocalStorage()

const enhancers = compose(
    applyMiddleware(thunkMiddleware, sessionInjector),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

const store = createStore(
    rootReducer,
    // persistedState,
    enhancers
)

// store.subscribe(() => saveToLocalStorage(store.getState()))
// store.subscribe(() => store.getState())
export default store;