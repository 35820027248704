import axios from 'axios';
import store from '../store/createStore';
import Toast from '../components/Toast/toast';

export default store => next => action => {
    // if (!store.getState().user.authenticated && !store.getState().register.authenticated) {
    //     return next(action);
    // }

    const TOKEN = "TOKEN";

    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem(TOKEN);
        config.headers.Authorization = token ? `Bearer ${token}` : undefined;
        config.headers.platform = "superagent_web";
        return config;
    });

    axios.interceptors.response.use(function (response) {
        if (response.data) {
            if (response.data.code === 401) {
                Toast(response.data.message, "error");
                return setTimeout(() => {
                    window.location.href = "/login";
                }, 2000);
            }
        }
        return response;
    });

    return next(action);
};