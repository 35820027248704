import React, {useState} from 'react'
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import Dropdown from '../../components/dropdown/dropdown';
import './css/upgrade-account-doc.css'
import UploadInput from '../../components/upload-input/upload-input';
import Toast from '../../components/Toast/toast';
import axios from 'axios';


interface Props {
    returningUser?: boolean,
    closeModal: (data: boolean) => void
}

export default function UpgradeAccountDoc(props: Props) {
    const {
        closeModal,
    } = props;

    const [processing, setProcessing] = useState(false);
    const [file, setFile] = useState("");

    const saveDoc = async () => {
        setProcessing(true);
        try {
            let formData = new FormData();
            formData.append("id_files", file);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/verification/document`, formData);
            console.log(res.data)
            if (res.data && res.data.code === 200) {
                Toast(res.data.message, "success");
                setTimeout(() => {
                    // window.location.href = "/overview";
                    window.location.reload();
                }, 2000);
            } else {
                // res.data?.data
                Toast(res.data.message, "error");
            }
            setProcessing(false);

        } catch (error) {
            console.log(error.response)
        }
    }

    return (
        <>
            {/* upgraact <div className="box-management"> */}
            {/* <div className="logo-area">
                    <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo"/>
                </div> */}
            <div className="upgraact-central-area">
                <div className="text-area">Verify Your Identity (Step 2/2)</div>
                <div style={{ height: 40 }}></div>

                <div className="upload-information">Upload Identification</div>
                <div style={{ height: 8 }} />
                <UploadInput
                    id="upgrade-account"
                    type="file" labelName="Select .PDF or .JPG Files"
                    appearance="dotted-fade"
                    getFile={(file: any) => setFile(file)}
                />

                <div style={{ height: 40 }} />
                <div className="upgraact-submit-button">
                    <div className="first-btn">
                        <Button btn_name="Cancel" color="btn-default" onClick={() => closeModal(true)} />
                    </div>
                    <div className="second-btn">
                        <Button
                            btn_name="Submit Verification"
                            color="btn-blue"
                            loading={processing}
                            onClick={saveDoc}
                        />
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}