import React from 'react'
import { JsxElement } from 'typescript'
import './css/selectbox.css'

interface Item {
    state: string,
    name: string,
    icon: JSX.Element,
    index: string
}

interface Props {
    returningUser?: boolean,
    item: Item,
    className?: string
    // handleOnClick: (index: number) => number
    handleOnClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

// export default function Selectbox() {
export default function Selectbox(props: Props) {
    const {
        returningUser,
        item,
        handleOnClick,
        className
    } = props;

    return (
        <>
            {/* <a className="select-anchor">
                <div className="selectbox active-selectbox">
                    <i className="fas fa-signal"></i>
                    <span className="selection-name">label 1</span>
                </div>
            </a>

            <a className="select-anchor">
                <div className="selectbox inactive-selectbox">
                    <i className="fas fa-signal"></i>
                    <span className="selection-name">label 2</span>
                </div>
            </a> */}

            <a id={item.index} onClick={handleOnClick} className={`select-anchor ${className}`}>
                <div className={`selectbox ${item.state}`}>
                    {item.icon}
                    <span className="selection-name">{item.name}</span>
                </div>
            </a>

         

        </>
    )
}
