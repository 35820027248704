import React, { useEffect, useMemo, useState } from 'react'
import './css/transactions.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchTransactions,
    fetchTransactionById
} from '../../actions/transactions';
import Loader from '../../components/loader/loader';
import SettingsNav from '../../components/setting-nav/setting-nav';
import { useParams } from 'react-router';
import Table2 from '../../components/table-v2/table2';
import { CSVLink } from "react-csv";

const settingNavContent = [
    {
        "state": "settings-active",
        "name": "VAS",
        "path": "/settings-Profile Details"
    },
    {
        "state": "settings-inactive",
        "name": "Air Pay",
        "path": "/settings-Business Details"
    },
    {
        "state": "settings-inactive",
        "name": "Universal Recharge",
        "path": "/settings-Banking Details"
    }
]

interface Props {
    returningUser?: boolean,
    fetchTransactions: () => void,
    fetchTransactionById: (agentId: string) => void,
    users: UserProps,
    transactions: TransactionProps
}

interface TransactionProps {
    loading: boolean,
    transactions?: TransactionIDProps,
}

interface TransactionIDProps {
    vas: any[],
    airpay: any[],
    unirech: any[],
}

interface UserProps {
    sa_details?: any,
}

interface VasProps {
    transaction: any[]
}

function Vas(props: VasProps) {
    let updated = props.transaction.map((elem, index) => {
        return {
            "service": elem["service"]?.name,
            "account": elem["account"],
            "amount": `₦ ${elem["amount"]}`,
            "reference": elem["transaction_id"],
            "status": elem["status"] === "success" ? "success" : "failure",
            "date": new Date(elem["created_at"]).toDateString(),
        }
    })
    return (
        <>
            <Table2
                data={updated}
                // columns={updated.map(x => Object.keys(x)[0])}
                columns={["Service", "Account", "Amount", "Reference", "Status", "Date"]}
                onClick={(e: any) => window.location.href = `/transactions/details/vas/${props.transaction.filter((detail) => detail["transaction_id"] == e?.reference)[0]["transaction_id"]}`}
            />
        </>
    )
}

// moment(elem["created_at"]).format('MMMM d, YYYY'),
function AirPay(props: VasProps) {
    const { transaction } = props;

    let updated = props.transaction.map((elem, index) => {
        return {
            "name": elem["user"]?.user_data?.name,
            "terminal id": elem["terminalid"],
            "amount": `₦ ${elem["amount"]}`,
            "rrn": elem["retrievalreferencenumber"],
            "reference": elem["transaction_id"],
            "status": elem["responsecode"] === "00" ? "success" : "failure",
            "date": new Date(elem["created_at"]).toDateString(),
        }
    })
    return (
        <>
            <Table2
                data={updated}
                columns={["Name", "Terminal ID", "Amount", "RRN", "Status", "Date"]}
                onClick={(e: any) => window.location.href = `/transactions/details/airpay/${props.transaction.filter((detail) => detail["transaction_id"] == e.reference)[0]["transaction_id"]}`}
            />
        </>
    )
}

function Unirech(props: VasProps) {
    const { transaction } = props;
    let updated = props.transaction.map((elem, index) => {
        return {
            "service": elem["service"]?.name,
            "account": elem["account"],
            "amount": `₦ ${elem["amount"]}`,
            "reference": elem["transaction_id"],
            "status": !elem["status"] ? "failure" : "success",
            "date": new Date(elem["created_at"]).toDateString(),
        }
    })
    return (
        <>
            <Table2
                data={updated}
                columns={["Service", "Account", "Amount", "Reference", "Status", "Date"]}
                onClick={(e: any) => window.location.href = `/transactions/details/unirech/${props.transaction.filter((detail) => detail["transaction_id"] == e?.reference)[0]["transaction_id"]}`}
            />
        </>
    )
}


function Transactions(props: Props) {
    const {
        fetchTransactions,
        fetchTransactionById,
        transactions: {
            loading,
            transactions,
        },
    } = props;

    const [pagenumber, setPagenumber] = useState(() => 0)
    const [exporting, setExporting] = useState(false);
    const { id } = useParams<{ id: string }>() || 1;

    useEffect(() => {
        if (id) {
            const fetchWait = async () => {
                await fetchTransactionById(id)
            }
            fetchWait()
        } else {
            const fetchWait = async () => {
                await fetchTransactions()
                // await fetchTransactionById(id)
            }
            fetchWait()
        }
    }, [fetchTransactionById, fetchTransactions, id])

    const getType = useMemo(() => {
        switch (pagenumber) {
            case 0:
                return 'vas'
            case 1:
                return 'airpay'
            case 2:
                return 'unirech'
            default:
                return 'vas'
        }
    }, [pagenumber])

    return (
        <>
            <div className="box-management-main">

                <SettingsNav navContent={settingNavContent} handleClick={index => {
                    // setsettingPage(prev => index)
                    setPagenumber(prev => index)
                }} />

                <div className="transaction-pseudo-body">
                    {transactions && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CSVLink
                            className="btn-export"
                            data={transactions[getType]}
                        >
                            {!exporting ? "Export CSV" : "Exporting..."}
                        </CSVLink>
                    </div>}

                    <br />

                    {loading && <Loader className="loader-lg" />}
                    <>
                        {pagenumber === 0 && transactions && <Vas transaction={transactions.vas || []} />}
                        {pagenumber === 1 && transactions && <AirPay transaction={transactions.airpay || []} />}
                        {pagenumber === 2 && transactions && <Unirech transaction={transactions.unirech || []} />}
                    </>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        users: state.users,
        transactions: state.transactions
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    fetchTransactions,
    fetchTransactionById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);