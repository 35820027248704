import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import Dropdown from '../../components/dropdown/dropdown';
import './css/upload-document.css'
import UploadInput from '../../components/upload-input/upload-input';
// import {
//     completeRegistration,
//     fetchStates,
//     fetchCityByStateId
// } from '../../actions/auth';
import Loader from '../../components/loader/loader';
import { saveDocument } from '../../actions/users';


interface Props {
    returningUser?: boolean,
    states: any[],
    cities: any[],
    loading: boolean,
    isState: boolean,
    isCity: boolean,
    fetchCity: (id: string) => void,
    saveDocument: (payload: any) => void,
    closeModal: (data: boolean) => void
}

// export const BankingDetails: React.FC<Props> = ({returningUser}) => {
function UploadDocument(props: Props) {
    const { 
        cities,
        states,
        loading,
        fetchCity,
        isState,
        isCity,
        saveDocument,
        closeModal
     } = props;

    const [processing, setProcessing] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [rcNumber, setRCNumber] = useState("");
    const [cacDoc, setCACDoc] = useState("");
    const [role, setRole] = useState("");
    const [companyAddress, setAddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    const _fetchCityByStateId = (id: string) => {
        // setCities([]);
        fetchCity(id);
    }

    const handleSubmit = async () => {
        setProcessing(true);
        let formData = new FormData();
        formData.append("business_name", businessName);
        formData.append("business_number", rcNumber);
        formData.append("cac_files", cacDoc);
        formData.append("role", role);
        formData.append("company_address", companyAddress);
        formData.append("city_id", city);
        formData.append("state_id", state);

        await saveDocument(formData);
        setProcessing(false);
    }

    return (
        <>
            {/* <div className="box-management"> */}
            {/* <div className="logo-area">
                    <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo"/>
                </div> */}
            <div className="uplodoc-central-area">
                <div className="text-area">
                    Upload Business Document
                </div>

                {loading && isState && <>
                    <br />
                    <Loader className="loader-lg" />
                </>}

                {states.length > 0 && <>
                    <div style={{ height: 48 }} />
                    <TextInput labelName="Business Name" onInput={(e: any) => setBusinessName(e.target.value)} />

                    <div style={{ height: 24 }} />
                    <TextInput labelName="RC or BN Number" onInput={(e: any) => setRCNumber(e.target.value)} />

                    <br />
                    <div className="uplodoc-iconed-label">
                        <div className="uplodoc-text-info-area">
                            <div>Upload CAC Documents</div>
                        </div>
                        {/* <label htmlFor="files">Select file</label>
                        <input id="files" style={{display:""}} title="emmauel" type="file"/> */}
                        <UploadInput
                            id="cacDoc"
                            labelName="Select .PDF or .JPG Files"
                            appearance="dotted-fade"
                            type="file"
                            getFile={e => setCACDoc(e)}
                        />
                    </div>

                    <div style={{ height: 24 }} />
                    {/* <TextInput labelName="Your Role" onInput={(e: any) => setRole(e.target.value)} /> */}
                    <Dropdown
                        label="Your Role"
                        valueKey={"name"}
                        displayKey={"name"}
                        data={[{ id: 1, name: "Business Owner" }, { id: 2, name: "Admin" }, { id: 3, name: "Developer" }]}
                        onInput={(e: any) => setRole(e.target.value)}
                        disabled={isCity}
                    />

                    <div style={{ height: 24 }} />
                    <TextInput labelName="Company Address" onInput={(e: any) => setAddress(e.target.value)} />

                    <div style={{ height: 24 }} />
                    <Dropdown
                        label="State"
                        valueKey={"id"}
                        displayKey={"name"}
                        data={states}
                        onInput={(e: any) => {
                            setState(e.target.value)
                            _fetchCityByStateId(e.target.value)
                        }}
                    />
                    <div style={{ height: 24 }} />

                    <Dropdown
                        label="City"
                        valueKey={"id"}
                        displayKey={"name"}
                        data={cities}
                        onInput={(e: any) => setCity(e.target.value)}
                        disabled={isCity}
                    />

                    {isCity && <>
                        <br />
                        <Loader className="loader-lg" />
                    </>}

                    {/* <div style={{ height: 24 }} />
                <TextInput labelName="City" onInput={(e: any) => setCity(e.target.value)} /> */}

                    <div style={{ height: 24 }} />
                    <div className="uplodoc-submit-button">
                        <div className="first-btn">
                            <Button btn_name="Cancel" color="btn-default" onClick={() => closeModal(true)} />
                        </div>
                        <div className="second-btn">
                            <Button
                                btn_name="Save Documents"
                                onClick={handleSubmit}
                                color="btn-blue"
                                loading={processing}
                            />
                        </div>
                    </div>
                </>}
            </div>
            {/* </div> */}
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch: any) => bindActionCreators({ saveDocument }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);