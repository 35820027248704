import axios from 'axios';
import constants from '../constants/ActionTypes';
import Toast from '../components/Toast/toast';

export const fetchUserDetails = () => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent`);

        dispatch({
            type: constants.SA_USER_DETAILS,
            payload: res.data?.data
        })

    } catch (error) {
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }

    dispatch({ type: constants.LOADING_STOP })
}

export const saveDocument = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/business/documents`, payload);

        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}