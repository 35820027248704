import constants from '../constants/ActionTypes';

const merchantReducer = (state = {}, actions) => {
    switch (actions.type) {
        case constants.LOADING:
            return { ...state, loading: true }
        case constants.REQUEST:
            return { ...state, }
        case constants.LIST_MERCHANTS:
            return { ...state, merchants: actions.payload, loading: false }
        case constants.LIST_MERCHANTS_ID:
            return { ...state, single_merchant: actions.payload, loading: false }
        case constants.GET_AGENT:
            return { ...state, agent_details: actions.payload, loading: false }
        default:
            return state;
    }
}

export default merchantReducer;