import React from 'react'
import './css/table.css'

interface Props {
    headerRows: string[],
    columnData: Object[],
    onClick: (obj: any) => void
}

export default function Table(props: Props) {
    const renderRows = (obj: Object) => {
        let final = [];
        for (let i = 0; i < props.headerRows.length; i++) {
            const val = Object.values(obj)[i];
            const check_props = val === "FAILURE" ? "text-danger" : val === "SUCCESS" ? "text-success" : "";
            final.push(<td className={check_props}>{val}</td>)
        }
        return final;
    }

    return (
        <>
            <div className="table-container">
                <table>
                    {/* <>
                        <div className="text-danger">No data found</div>
                    </> */}
                    <>
                        <thead>
                            <tr>
                                {props.headerRows.map((e: string, i) => <th key={i}>
                                    {e && <div className="table-head-container">
                                        <h4>{e}</h4>
                                        <div className="arrow-container">
                                            <div className="arrow-up arrow-selected"></div>
                                            <div className="arrow-down arrow-unselected"></div>
                                        </div>
                                    </div>}
                                </th>)}
                            </tr>
                        </thead>

                        <tbody>
                            {props.columnData.length > 0 && props.columnData.map((e: any, i) => <tr key={i} onClick={() => props.onClick(e)}>
                                {renderRows(e)}
                            </tr>)}
                            {props.columnData.length === 0 && <>
                                <div className="text-danger">No data found</div>
                            </>}
                        </tbody>
                    </>
                </table>
            </div>
        </>
    )
}
