import React from 'react'
import back_arrow from '../../components/asset/bill-payments/back-arrow.svg';
import history from '../../config/history';
// import './css/label.css'

interface Props {
    customStyles?: object
}

export default function GoBack(props: Props) {
    return (
        <div
            style={props?.customStyles}
            className="back-text"
            onClick={() => window.history.back()}
        >
            <img src={back_arrow} /> Go Back
        </div>
    )
}
