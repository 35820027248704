import axios from "axios";
import constants from "../constants/ActionTypes";
import Toast from "../components/Toast/toast";

export const stopLoading = (payload) => async (dispatch) => {
  dispatch({ type: constants.STOP_LOADING });
};

export const UniversalReq = (payload) => async (dispatch) => {
  dispatch({ type: constants.LOADING });

  let res = {};
  try {
    if (payload["method"] == "get") {
      res = await axios.get(payload["url"]);
    } else if (payload["method"] == "post") {
      res = await axios.post(payload["url"], payload["data"]);
    }

    if (res.data && res.data.code === 200) {
      dispatch({
        type: constants.UNIVERSAL_REQUEST,
        payload: res.data.data,
      });
    } else {
      // res.data.data
      dispatch({
        type: constants.UNIVERSAL_REQUEST,
        payload: [],
      });
      Toast(res.data.message, "error");
    }
    console.log("UniReqRes : ", res);
  } catch (error) {
    Toast(res.data.data, "error");
  }
};

export const fetchPowerDistributors = (payload) => async (dispatch) => {
  dispatch({ type: constants.LOADING });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/services/power/distributors`
    );

    if (res.data && res.data.code === 200) {
      dispatch({
        type: constants.POWER_DISTRIBUTION_COMPANIES,
        payload: res.data.data,
      });
    } else {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const fetchDataPlans = (payload) => async (dispatch) => {
  dispatch({ type: constants.LOADING });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/services/internet/plans`,
      payload
    );

    if (res.data && res.data.code === 200) {
      dispatch({
        type: constants.DATA_PLANS,
        payload: res.data.data,
      });
    } else {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const fetchBanks = () => async (dispatch) => {
  dispatch({ type: constants.LOADING });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/transfer/banks`
    );

    if (res.data && res.data.code === 200) {
      dispatch({
        type: constants.LIST_BANKS,
        payload: res.data.data,
      });
    } else {
      Toast(res.data.message, "error");
    }
  } catch (error) {
    console.log(error.response);
  }
};
