import React, { useState } from 'react'
import './css/modal-dialog-box.css'
import './js/modal-dialog-box.js'

interface Props {
    children: any,
    modalState: boolean,
}

export default function ModalDialogbox(props: Props) {
    const { children, modalState } = props;

    const [modalShowState, modalToggle] = useState(() => modalState ? modalState.toString() : "")
    // const [modalShowState, modalToggle] = useState(() => "show-modal")

    const closeModel = () => {
        modalToggle(prev => prev === "close-modal" ? "show-modal" : "close-modal")
    }

    const _modalShowState = modalState ? "show-modal" : "close-modal";

    return (
        <>
            <div>

                <div id="myModal" className={`modal ${_modalShowState}`}>
                    <div className="modal-centre">
                        <div className="content-area">
                            <div onClick={closeModel} className="close">
                                X
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
