import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import TextInput from '../../components/text-input/text-input';
import Button from '../../components/button/button';
import './css/register-profile-details.css'
import Dropdown from '../../components/dropdown/dropdown';
import {
    completeRegistration,
    fetchStates,
    fetchCityByStateId
} from '../../actions/auth';
import Loader from '../../components/loader/loader';

// interface Props {
//     returningUser?: boolean,
//     fetchStates: () => void,
//     fetchCityByStateId: (id: string) => void,
//     // auth: AuthProps
// }

function RegisterProfileDetails(props) {
    const [processing, setProcessing] = useState(false);
    const [full_name, setFullName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [allStates, setStates] = useState([]);
    const [state, setState] = useState("");
    const [allCities, setCities] = useState(() => []);
    const [city, setCity] = useState("");
    const [isCity, setIsCity] = useState(false);
    const [referrer, setReferrer] = useState("");
    
    const {
        fetchStates,
        fetchCityByStateId,
        auth: {
            loading,
            states,
            cities,
            loadingCities
        }
    } = props;

    useEffect(() => {
        setReferrer(localStorage.getItem("referrer"));
    })

    useEffect(async () => {
        if (states) {
            setStates(states);
        }
        if (!allStates.length) {
            await fetchStates();
        }
        if (cities) {
            setCities(cities);
        }
    }, [states, state, cities])

    const _fetchCityByStateId = (id) => {
        // setCities([]);
        fetchCityByStateId(id);
        // setIsCity(true);
    }

    const handleSubmit = async () => {
        setProcessing(true);

        const {
            completeRegistration
        } = props;

        const payload = {
            "user_id": localStorage.getItem("user_id"),
            "name": full_name,
            "address": address,
            "city_id": city,
            "state_id": state,
            "referred_by": referrer,
        }

        await completeRegistration(payload);
        setProcessing(false);
    }

    return (
        <>
            <div className="box-management">
                <div className="logo-area-reg">
                    <img src={require("../../components/asset/dark-logo.svg").default} alt="" className="login-logo" />
                </div>

                <div className="central-area-auth">
                    <div className="text-area">
                        Tell us about yourself
                    </div>
                    <div className="text-info-area">
                        <p>Please ensure to enter accurate information about yourself for verification.</p>
                    </div>

                    {referrer && <div className="text-info-area-reg">
                        <code>Referred by: {referrer}</code>
                    </div>}

                    {loading && <Loader className="loader-lg" />}

                    {!loading && <div className="text-form-area-main">
                        <TextInput
                            labelName="Full Name"
                            onInput={(e: any) => setFullName(e.target.value)}
                        />
                        <br />
                        <TextInput
                            labelName="Address"
                            onInput={(e: any) => setAddress(e.target.value)}
                        />
                        <br />
                        <Dropdown
                            label="State"
                            valueKey={"id"}
                            displayKey={"name"}
                            data={allStates}
                            onInput={(e: any) => {
                                setState(e.target.value)
                                _fetchCityByStateId(e.target.value)
                            }}
                        />

                        <br />

                        <Dropdown
                            label="City"
                            valueKey={"id"}
                            displayKey={"name"}
                            data={allCities}
                            onInput={(e: any) => setCity(e.target.value)}
                            disabled={loadingCities}
                        />

                        {loadingCities && <>
                            <br />
                            <Loader className="loader-lg" />
                        </>}

                        <br />

                        <TextInput
                            value={referrer ?? ''}
                            onInput={e => setReferrer(e.target.value )}
                            type="text"
                            labelName="Referral Code (Optional)"
                            disabled
                        />
                    </div>}
                    <br />

                    {!loading && <div className="submit-button">
                        <Button
                            onClick={handleSubmit}
                            btn_name="Continue"
                            color="btn-blue"
                            loading={processing}
                        />
                    </div>}
                </div>
            </div>
        </>
    );
}

// interface AuthProps {
//     auth?: boolean,
// }

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

// const mapDispatchToProps = (dispatch: any) => bindActionCreators({
const mapDispatchToProps = (dispatch) => bindActionCreators({
    completeRegistration,
    fetchStates,
    fetchCityByStateId
}, dispatch);



export default connect(mapStateToProps, mapDispatchToProps)(RegisterProfileDetails);