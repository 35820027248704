import React from 'react';
import './css/transaction-receipt.css';
import { List, message, Avatar, Spin } from 'antd';
import moment from 'moment';

interface Props {
    date: string,
    recipient: string,
    description: string,
    status: string,
    reference: string,
    merchant: string,
    amount: number,
}

const TransactionReceipt = React.forwardRef((props: Props, ref: any) => {
    const {
        date,
        recipient,
        description,
        status,
        reference,
        merchant,
        amount
    } = props;

    const check_props = status === "success" ? "text-success" : !status ? "text-danger" : "";

    return (
        <div className="tr-receipt" ref={ref}>
            <div className="tr-receipt-title">Transaction Receipt</div>
            <div className="tr-receipt-jumbotron">
                <div>
                    <div className="tr-receipt-amount">{`₦${amount}`}</div>
                    <div className="tr-receipt-name">{merchant.toUpperCase()}</div>
                </div>
                <div>
                    <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.52802 23.2427L8.55701 23.2683C9.28701 23.9211 18.133 31.8261 18.437 32H10.594C9.44399 32 8.51101 30.9632 8.51401 29.6853L8.52802 23.2427ZM8.905 16C17.625 20.1813 24.582 31.4837 24.582 31.4837C15.696 22.2966 5.444 19.1979 4.763 18.9995L4.72699 18.9888H8.905V16ZM8.72198 6.70936C8.72198 6.70936 22.018 15.7045 25.527 23.7419C25.527 23.7419 15.027 13.5744 3.30899 10.815L8.78201 11.232L8.72198 6.70936ZM0 0.0576381C5.012 -0.327428 10.242 1.34297 10.242 1.34297L24.727 1.68963C25.435 1.7067 26 2.3339 26 3.10296V16C17.212 3.39843 0 0.0576381 0 0.0576381Z" fill="#1B0E44" />
                    </svg>
                </div>
            </div>

            <div className="tr-receipt-list">
                <List
                    dataSource={[
                        { title: "Date", value: moment(date).format('MMMM d, YYYY') },
                        { title: "Recepient", value: recipient },
                        { title: "Description", value: description },
                        { title: "Status", value: status },
                        { title: "Reference", value: reference }
                    ]}
                    renderItem={item => (
                        <List.Item key={item.title}>
                            <List.Item.Meta
                                title={<div className="tr-receipt-list-title">{item.title}</div>}
                            />
                            <div className={`tr-receipt-list-value ${item.title === "Status" && check_props}`}>{item.value}</div>
                        </List.Item>
                    )}
                >

                </List>
            </div>
        </div>
    )
})

export default TransactionReceipt;