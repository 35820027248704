import React from 'react';
import './css/dropdown.css';

interface Props {
    data?: any[],
    label?: string,
    valueKey?: string,
    displayKey?: string,
    onInput: (e: any) => void,
    disabled?: boolean
}

export default function Dropdown(props: Props) {
    const {
        data,
        label,
        valueKey,
        displayKey,
        onInput,
        disabled
    } = props;
    const _valueKey = valueKey || "";
    const _displayKey = displayKey || "";

    return (
        <select onInput={onInput} className={`dropdown ${disabled ? "dropdown-disabled" : ''}`}>
            {label && <option>{label}</option>}
            {data?.map((e: any, i: any) => <option value={data[i][_valueKey]} key={i}>{e[_displayKey]}</option>)}
        </select>
    )
}