import React from 'react'
import Button from '../button/button'
import './css/deactivate-merchant.css'

interface Props {
  toggleActivation: () => void,
  toggle: boolean,
  activationRequested: boolean,
}

export default function DeactivateMerchant(props: Props) {
  return (
    <div className="deactivate-box">
      <div className="thick-deco">
        <div className="first-circle">
          <div className="second-circle">
            <div className="thick-box">
              <img src={require("../asset/failed.svg").default} alt="check-mark" className="check-mark" />
            </div>
          </div>
        </div>
      </div>
      <div className="text-area">
        <div className="title">
          {props.activationRequested ? 'Deactivate Merchant' :
            'Activate Merchant'}
        </div>
        {props.activationRequested ? <div className="body-section">
          You are about to deactivate this Merchant.
          All transactions on the account will be suspended immediately.
        </div> :
          <div className="body-section">
            You are about to activate this Merchant.
            All transactions on the account will be restored immediately.
          </div>}
      </div>
      <div className="btn-area">
        <Button
          btn_name={`Yes, ${props.activationRequested ? 'Deactivate' : 'Activate'}`}
          color="btn-default"
          // customStyles={{ border: "0.8px solid #1B0E44", backgroundColor: "#fff" }}
          loading={props?.toggle}
          disabled={props?.toggle}
          onClick={props?.toggleActivation}
        />
      </div>
    </div>
  )
}
