import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import TextInput from '../../components/text-input/text-input';
import Checkbox from '../../components/checkbox/checkbox';
import Button from '../../components/button/button';
import './css/login.css';
import Card from '../../components/card/card';
import { Link } from 'react-router-dom';
import { doLogin } from '../../actions/auth';
import Toast from '../../components/Toast/toast';

interface Props {
    returningUser?: boolean,
    // doLogin(): void
}

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const doLogin = async () => {
        setLoading(true);
        const { doLogin } = props;
        const payload = {
            "email": email,
            "password": password
        }
        await doLogin(payload);
        setLoading(false);
    }
    return (
        <>
            <div className="login-box-management box-bg">
                <div className="logo-area-login">
                    <img src={require("../../components/asset/airvendlogo 1.svg").default} alt="" className="login-logo" />
                </div>

                <div style={{ height: 36 }} />

                <Card className="box-card">
                    <div className="central-area">
                        <div className="text-area">
                            Login
                    </div>
                    <br />
                    <br />
                        <div className="text-form-area">
                            <TextInput
                                labelName="Phone Number"
                                onInput={e => setEmail(e.target.value)}
                            />
                            <br />
                            <TextInput
                                type="password"
                                labelName="Password"
                                iconPosition="right-icon"
                                onInput={e => setPassword(e.target.value)}
                                iconJSX={<img src={require("../../components/asset/eye.svg").default} />}
                            />
                        </div>
                        {/* <br /> */}
                        <div className="checkbox-area">
                            <Checkbox />
                        </div>
                        <div className="submit-button">
                            <Button
                                btn_name="Sign in"
                                color="btn-green"
                                onClick={doLogin}
                                loading={loading}
                            />
                        </div>
                    </div>
                </Card>
                <div className="information-elements">
                    <div className="create">
                        <span>Not Registered?</span><Link to="/register">Create Account</Link>
                    </div>
                    <Link to="/forgot-password">Forgot Password</Link>
                </div>
            </div>
        </>
    );
}

interface LoginProps {
    login?: boolean,
}

function mapStateToProps(state: LoginProps, ownProps: Object) {
    return {
        login: state.login
    }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    doLogin,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login)