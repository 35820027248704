import constants from "../constants/ActionTypes";

const utilsReducer = (state = { banks: [] }, actions) => {
  switch (actions.type) {
    case constants.LOADING:
      return { ...state, loading: true };
    case constants.STOP_LOADING:
      return { ...state, loading: false };
    case constants.POWER_DISTRIBUTION_COMPANIES:
      return { ...state, disco: actions.payload, loading: false };
    case constants.DATA_PLANS:
      return { ...state, dataPlan: actions.payload, loading: false };
    case constants.LIST_BANKS:
      return { ...state, banks: actions.payload, loading: false };
    default:
      return state;
  }
};

export default utilsReducer;
