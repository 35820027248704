import React from 'react'
import './css/card.css'

interface Props {
    children?: any,
    className?: string
}

{/* <div style={{ width: "100%" }}>
    <div className={`card-custom ${props.className}`}>
        {props.children}
    </div>
</div> */}

export default function Card(props: Props) {
    return (
        <div className={`card-custom ${props.className}`}>
            {props.children}
        </div>
    )
}
