import React, { useState } from 'react'
import './css/setting-nav.css'
import Selectbox from '../selectbox/selectbox';
// export default function Sidebar() {


interface navItem {
    state: string,
    name: string,
    path?: string
}

interface Props {
    returningUser?: boolean,
    navContent: navItem[],
    handleClick: (index: number) => void
}

export default function SettingsNav(props: Props) {
    const { handleClick, navContent } = props

    const [navBarContentObj, setnavBarContentObj] = useState(() => navContent)


    const handleClickFnc = (indexParam: number) => {
        setnavBarContentObj(prev => {
            const newElems = prev.map((elem, i) => {
                if(i == indexParam){
                    elem = {...elem, "state":"settings-active"}
                    return elem
                }else {
                    elem = {...elem, "state":"settings-inactive"}
                    return elem
                }
            })
            return newElems
        })
        handleClick(indexParam)
    }

    // const handleClickFnc = (nm:string) => props.name(nm)
    return (
        <>

            <nav className="setttings-nav">

                {navBarContentObj.map((navElem, index) =>
                     <div onClick={() => handleClickFnc(index)} className="settings-nav-item" key={index}>
                        {/* <div className="settings-nav-item"> */}
                            {navElem.name}
                            <div className={`bottom-style ${navElem.state}`}></div>
                        {/* </div> */}
                     </div>
                )}
            </nav>
        </>
    )
}
