import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import transactions from "./transactions";
import merchants from "./merchants";
import utils from "./utils";

export default combineReducers({
  auth,
  users,
  transactions,
  merchants,
  utils,
});
