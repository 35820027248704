import React from 'react'
import './css/label.css'

interface Props {
    name: string,
    className?: string
}

export default function Label(props: Props) {
    return (
        <label className={`label label-text ${props.className || "label-primary"}`}>{props.name}</label>
    )
}
