import React, { useEffect, useState } from 'react'
// import useState from 'react'
import './css/text-input.css'
// import './js/text-input.js'

interface Props {
  labelName: string,
  type?: string,
  onInput?: any,
  iconPosition?: string,
  iconJSX?: JSX.Element,
  value?: string,
  handleKeyUp?: any,
  waitKeypress?: boolean,
  disabled?: boolean,
}

const unstarredInput = <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.94803 7.318C1.8585 7.56242 1.6772 7.76229 1.44264 7.87516C1.20809 7.98803 0.938768 8.00499 0.691907 7.92244C0.445045 7.83989 0.240099 7.66434 0.120614 7.43309C0.00112975 7.20184 -0.0234759 6.93311 0.0520256 6.684C0.0320256 6.744 0.0520256 6.682 0.0520256 6.682C0.0876876 6.57191 0.129077 6.46377 0.176026 6.358C0.256026 6.168 0.374026 5.906 0.536026 5.596C0.864026 4.976 1.38003 4.15 2.13603 3.324C3.67003 1.654 6.17603 0 10 0C13.826 0 16.332 1.654 17.862 3.324C18.6789 4.21982 19.3412 5.2451 19.822 6.358C19.862 6.452 19.892 6.528 19.912 6.584C19.92 6.6 19.94 6.704 19.96 6.804C19.978 6.896 19.994 6.984 20 7C20 7 20.168 7.666 19.316 7.948C19.0651 8.03179 18.7912 8.01275 18.5543 7.89505C18.3174 7.77735 18.1368 7.57057 18.052 7.32V7.314L18.038 7.282C17.9376 7.02508 17.8227 6.77401 17.694 6.53C17.339 5.85915 16.9002 5.23615 16.388 4.676C15.168 3.346 13.174 2 10 2C6.82603 2 4.83203 3.346 3.61203 4.676C2.95151 5.40014 2.41436 6.22781 2.02203 7.126C2.00103 7.17761 1.98103 7.22962 1.96203 7.282L1.94803 7.318Z" fill="black"/>
                          <path d="M6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8Z" fill="black"/>
                        </svg>

const starredInput =   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.219977 0.219828C0.0929589 0.346817 0.0157307 0.515171 0.00233427 0.69428C-0.0110622 0.873389 0.0402627 1.05136 0.146977 1.19583L0.219977 1.27983L4.25398 5.31483C2.28701 6.69346 0.882863 8.73488 0.298977 11.0648C0.253956 11.2566 0.286215 11.4584 0.388782 11.6267C0.491349 11.7949 0.655986 11.916 0.847113 11.9638C1.03824 12.0116 1.24051 11.9823 1.4102 11.8822C1.57989 11.7821 1.70338 11.6192 1.75398 11.4288C2.27288 9.36083 3.55088 7.56378 5.33398 6.39483L7.14398 8.20483C6.42097 8.96032 6.02257 9.96891 6.0341 11.0146C6.04563 12.0602 6.46619 13.0598 7.20568 13.7991C7.94517 14.5385 8.9448 14.9589 9.99045 14.9702C11.0361 14.9816 12.0446 14.583 12.8 13.8598L18.719 19.7798C18.8528 19.914 19.0325 19.9926 19.2219 19.9997C19.4112 20.0068 19.5963 19.942 19.7398 19.8182C19.8833 19.6945 19.9747 19.5209 19.9955 19.3326C20.0163 19.1442 19.965 18.9549 19.852 18.8028L19.779 18.7188L13.666 12.6048L13.667 12.6028L6.71698 5.65683L6.71898 5.65483L5.58598 4.52483L1.27998 0.219828C1.13935 0.0793779 0.948727 0.000488281 0.749977 0.000488281C0.551226 0.000488281 0.360602 0.0793779 0.219977 0.219828ZM9.99998 3.49983C8.99998 3.49983 8.02998 3.64783 7.11098 3.92483L8.34798 5.16083C10.4875 4.73719 12.7081 5.15191 14.5505 6.31926C16.3929 7.4866 17.7162 9.3173 18.247 11.4328C18.2988 11.6218 18.4225 11.7829 18.5916 11.8818C18.7607 11.9808 18.9617 12.0096 19.1518 11.9622C19.3419 11.9148 19.5059 11.7948 19.6087 11.6281C19.7115 11.4613 19.745 11.261 19.702 11.0698C19.1597 8.90729 17.9105 6.98789 16.1528 5.6164C14.395 4.24492 12.2295 3.49996 9.99998 3.49983ZM10.195 7.00983L13.996 10.8098C13.9468 9.81773 13.5305 8.87934 12.8281 8.17705C12.1256 7.47476 11.1871 7.05874 10.195 7.00983Z" fill="black"/>
                      </svg>
              


export default function TextInput(props: Props) {

  const [labelState, changeLabelState] = useState("select-item-name")
  const [inputType, changeinputType] = useState(props.type)
  const [iconElement, changeiconElement] = useState(props.iconJSX)

  useEffect(() => {
    changeiconElement(prev => {
      if(inputType == "password"){
        return starredInput
      }else{
        return unstarredInput
      }
    })
  }, [inputType])


  const showLabel = () => {
    // console.log("show label")
    changeLabelState("select-item-name-show")
  }

  const togglePassword = () => {
    changeinputType(prev => {
      if (props.type == "password")
        return prev == "password" ? "text" : "password"
    })
  }

  const handleKeypressFnc = (e: any) => {
    props.handleKeyUp(e)
  }
  return (
    <>
      {/* <div className="selected">
          <input type="text" defaultValue="" className="textfield" placeholder="Phone Number" name="unique-name" />
          <label htmlFor="unique-name" className="select-item-name">Label</label>
        </div> */}
      <div onClick={showLabel} className={`selected ${props.iconPosition}`}>
        <div onClick={togglePassword} className="iconic-div">
          {iconElement}
        </div>
        <div className="selected-label">
          <input 
            disabled={props.disabled}
            onKeyUp={(e) => {
              if(props.waitKeypress){
                handleKeypressFnc(e)
              }
            }}
            defaultValue={props.value || ""}
            type={inputType ? inputType : "text"}
            // defaultValue=""
            className="textfield"
            placeholder={props.labelName}
            id={props.labelName}
            name="unique-name"
            onInput={props.onInput}
            style={{display:props.type == "file" ? "none" : ""}}
          />
          <label htmlFor="unique-name" className={labelState}>{props.labelName}</label>
        </div>
      </div>
    </>
  )
}
