import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from '../src/store/createStore';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './components/asset/css/main.css';
import './components/asset/css/responsive.css';
import './components/asset/css/animations.css';
import './components/asset/css/navbar.css';
// import './index.css';

import AppRoutes from './routes';
import { Toaster } from 'react-hot-toast';
// import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppRoutes />
      <Toaster />
      {/* <App /> */}
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

