import axios from 'axios';
import constants from '../constants/ActionTypes';
import Toast from '../components/Toast/toast';

export const fetchMerchants = () => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/agents`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_MERCHANTS,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
    }
}

export const getAgent = (agent_id: string) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/agents/${agent_id}`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.GET_AGENT,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
    }
}