import axios, { AxiosInstance, AxiosRequestConfig, } from 'axios';
import qs from 'qs';

const token = localStorage.getItem('TOKEN');

const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
        platform: "agent_web",
        Authorization: token ? `Bearer ${token}` : undefined
    },
    paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false })
    },
};

const instance: AxiosInstance = axios.create(config);

export default instance;