import React, { useState, useEffect } from 'react'
import './css/otp-4.css'

interface Props {
  setVal: (e: any) => void,
  id?: string
}

export default function Otp4(props: Props) {
  let { id } = props;
  id = !id ? "one_" : `${id}_`;
  const [currID, setCurrID] = useState("");

  useEffect(() => {
    document.getElementById(`one_one`)?.focus();
  }, [])

  const moveCursor = (newId: string) => {
    document.getElementById(newId)?.focus();
    setCurrID(newId);
    setInput();
  }

  var input = document.getElementById('myInput');

  if (input) {
    input.addEventListener('keydown', function (event) {
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        return false;
      }
    });
  }

  const setInput = () => {
    let currVal = "";
    [`${id}one`, `${id}two`, `${id}three`, `${id}four`].map(e => {
      let f = (document.getElementById(e) as HTMLInputElement).value;
      currVal = f ? currVal.concat(f) : currVal.concat("");
    })
    props.setVal(currVal);
  }

  const myKeyPress = (e: any) => {
    var keynum;

    if (window.event) { // IE                  
      keynum = e.keyCode;
    } else if (e.which) { // Netscape/Firefox/Opera                 
      keynum = e.which;
    }

    if (keynum === 8) {
      setTimeout(() => {
        delValAndFocus();
        setInput();
      }, 0);
    }
  }

  const handleKeyPress = (event: any, key: string) => {
    if (event.key) {
      moveCursor(`${id}${key}`.toString())
    }
  }

  const delValAndFocus = () => {
    switch (currID) {
      case `${id}four`:
        document.getElementById(`${id}three`)?.focus();
        setCurrID(`${id}three`)
        break;
      case `${id}three`:
        document.getElementById(`${id}two`)?.focus();
        setCurrID(`${id}two`)
        break;
      case `${id}two`:
        document.getElementById(`${id}one`)?.focus();
        setCurrID(`${id}one`)
        break;
      case `${id}one`:
        document.getElementById(`${id}one`)?.focus();
        break;
      default:
        break;
    }
  }

  return (
    <div className="otp-box">
      <div className="textfield-box textfield-active">
        <input
          id={`${id}one`}
          onChange={e => {
            if (e.target.value.length) {
              moveCursor(`${id}two`)
            }
          }}
          // onKeyPress={e => handleKeyPress(e, "two")}
          autoFocus={true}
          maxLength={1}
          type="password"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield first-textfield"
        />
      </div>
      <div className="textfield-box">
        <input 
          // id="two"
          id={`${id}two`}
          // onKeyPress={e => handleKeyPress(e, "three")}
          onChange={e => {
            if (e.target.value.length) {
              moveCursor(`${id}three`)
            }
          }}
          maxLength={1}
          type="password"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input
          // id="three"
          id={`${id}three`}
          // onKeyPress={e => handleKeyPress(e, "four")}
          onChange={e => {
            if (e.target.value.length) {
              moveCursor(`${id}four`)
            }
          }}
          maxLength={1}
          type="password"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
      <div className="textfield-box">
        <input 
          // id="four"
          id={`${id}four`}
          // onKeyPress={e => handleKeyPress(e, "four")}
          onChange={e => {
            if (e.target.value.length) {
              moveCursor(`${id}four`)
            }
          }}
          maxLength={1}
          type="password"
          defaultValue=""
          onKeyDown={myKeyPress}
          className="textfield other-textfield"
        />
      </div>
    </div>
  )
}