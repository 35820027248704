export default {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  RESET_AUTH: "RESET_AUTH",
  LOADING_STOP: "LOADING_STOP",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  INPUT_DATA: "INPUT_DATA",
  LIST_TRANSACTIONS: "LIST_TRANSACTIONS",
  LIST_AGENTS: "LIST_AGENTS",
  LIST_BASE_COMMISSIONS: "LIST_BASE_COMMISSIONS",
  LIST_TRANSACTIONS_ID: "LIST_TRANSACTIONS_ID",
  LIST_MERCHANTS: "LIST_MERCHANTS",
  LIST_MERCHANTS_ID: "LIST_MERCHANTS_ID",
  GET_AGENT: "GET_AGENT",
  WALLET_DETAILS: "WALLET_DETAILS",
  WALLET_AVAILABLE_BALANCE: "WALLET_AVAILABLE_BALANCE",
  LIST_STATES: "LIST_STATES",
  LIST_CITY_BY_STATE_LOADING: "LIST_CITY_BY_STATE_LOADING",
  LIST_CITY_BY_STATE: "LIST_CITY_BY_STATE",
  SA_USER_DETAILS: "SA_USER_DETAILS",
  SIGN_OUT: "SIGN_OUT",
  REQUEST: "REQUEST",
  LIST_BANKS: "LIST_BANKS",
  DATA_PLANS: "DATA_PLANS",
  SEND_MONEY: "SEND_MONEY",
  POWER_DISTRIBUTION_COMPANIES: "POWER_DISTRIBUTION_COMPANIES",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UNIVERSAL_REQUEST: "UNIVERSAL_REQUEST",
  SALES_REPORT: "SALES_REPORT",
};
