import axios from 'axios';
import constants from '../constants/ActionTypes';
import Toast from '../components/Toast/toast';

export const UniversalReq = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    let res = {};
    try {
        if (payload["method"] == "get") {
            res = await axios.get(payload["url"]);
        }

        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.UNIVERSAL_REQUEST,
                payload: res.data?.data
            })
        } else {
            // res.data?.data
            dispatch({
                type: constants.UNIVERSAL_REQUEST,
                payload: []
            })
            Toast(res.data.message, "error");
        }
        console.log("UniReqRes : ", res)
    } catch (error) {
        Toast(res.data?.data, "error")
    }
}

export const fetchTransactionById = (agentId) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        console.log(`do fetchTransactionById(${agentId}) to update state transactions`)
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superagent/agents/${agentId}/transactions`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_TRANSACTIONS_ID,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }

        console.log(res)
    } catch (error) {
        console.log(error.response)
    }
}

export const fetchTransactions = () => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superagent/transactions`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_TRANSACTIONS,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }

        console.log(res)
    } catch (error) {
        console.log(error.response)
    }
}

export const salesReport = (agent_id) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/report/sales/${agent_id}`);
        return res.data;

    } catch (error) {
        console.log(error.response)
    }
}