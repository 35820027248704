import axios from "axios";
import constants from "../constants/ActionTypes";
import Toast from "../components/Toast/toast";

export const sendMoney = (payload) => async (dispatch) => {
  dispatch({ type: constants.LOADING });

  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/transfer/send`,
      data: payload,
    };

    const res = await axios(config);

    return res;
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: constants.FAILURE,
      errors: error.response,
    });
  }
};
