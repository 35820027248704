import axios from 'axios';
import constants from '../constants/ActionTypes';
import Toast from '../components/Toast/toast';

export const doLogin = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOGIN_REQUEST })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/auth/login`, payload);
        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            const TOKEN = "TOKEN";
            localStorage.setItem(TOKEN, res.data?.data.access_token);

            //check user status
            const sa = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent`);
            if (sa) {
                localStorage.setItem("username", sa.data?.data?.user_data?.name?.split(" ")[0]);
            }
            const {
                data: {
                    bvn_verified,
                    kyc_updated,
                    doc_uploaded,
                    bank_account_added
                }
            } = sa.data;

            if (!kyc_updated) {
                window.location.href = "/register/profile";
            }
            if (bvn_verified && bank_account_added && doc_uploaded) {
                window.location.href = "/overview";
            } else {
                window.location.href = "/verify-account";
            }
        } else {
            Toast(res.data.message, "error");
        }
    } catch (error) {
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}



export const updateProfile = (payload) => async (dispatch) => {
    try {
        const res = await axios.post(payload.url, payload.data);
        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            console.log("updateProfile : ", res)
        } else {
            // res.data?.data
            Toast(res.data.message, "error");
            console.log("else updateProfile : ", res)
        }

    } catch (error) {
        console.log("error 1 : ", error)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })

        // dispatch({ type: constants.LOADING_STOP })
        Toast(error.response, "error");
        console.log("error updateProfile : ", error)
    }
}



export const verifyOTP = (otp) => async (dispatch) => {
    dispatch({ type: constants.REGISTER })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/verification/otp/${otp}`);
        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            localStorage.setItem("user_id", res.data?.data.user_id);
            setTimeout(() => {
                window.location.href = "/register/profile";
            }, 2000);
        } else {
            // res.data?.data
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}

export const doRegister = (payload) => async (dispatch) => {
    dispatch({ type: constants.REGISTER })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/register`, payload);
        console.log(res)
        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            dispatch({
                type: constants.REGISTER_SUCCESS,
                payload: res.data,
                phone_number: payload.phone_number
            })
        } else {
            Object.values(res.data?.data)?.map(e => Toast(e[0], "error"))
        }
    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}

export const addInput = (data) => {
    return {
        type: constants.INPUT_DATA,
        payload: data
    }
}

// interface UniversalReqInterface {
//     method: string,
//     url: string
// }

export const UniversalReq = (payload) => async (dispatch) => {
    
    if(payload.notloading === true){
        // 
    }else{
        dispatch({ type: constants.LOADING })
    }

    let res = {};
    try{
        if(payload["method"] == "get"){
            res = await axios.get(payload["url"]);
        }else if(payload["method"] == "post"){
            res = await axios.post(payload["url"], payload["data"]);
        }

        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.UNIVERSAL_REQUEST,
                payload: res.data?.data
            })
        } else {
            // res.data?.data
            dispatch({
                type: constants.UNIVERSAL_REQUEST,
                payload: []
            })
            Toast(res.data.message, "error");
        }
    } catch (error) {
        Toast(res.data?.data, "error")
    }
}


export const fetchStates = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/location/160/states`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_STATES,
                payload: res.data?.data
            })
        } else {
            // res.data?.data
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}

export const fetchTransactionById = (agentId) => async (dispatch) => {
    dispatch({ type: constants.LOADING })
    
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superagent/agents/${agentId}/transactions`);
        if(res.data && res.data.code === 200){
            dispatch({
                type: constants.LIST_TRANSACTIONS_ID,
                payload: res.data?.data
            })
        }else{
            Toast(res.data.message, "error");
        }
        
    } catch (error) {
        console.log(error.response)

    }

}


export const fetchTransactions = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })
    
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superagent/transactions`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_TRANSACTIONS,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }
        
    } catch (error) {
        console.log(error.response)

    }
}


export const fetchCityByStateId = (id) => async (dispatch) => {
    dispatch({ type: constants.LIST_CITY_BY_STATE_LOADING })

    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/location/${id}/cities`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_CITY_BY_STATE,
                payload: res.data?.data
            })
        } else {
            // res.data?.data
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}

export const completeRegistration = (payload) => async (dispatch) => {
    dispatch({ type: constants.REGISTER })

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/kyc`, payload);
        console.log(res)
        if (res.data && res.data.code === 200) {
            Toast(res.data.message, "success");
            setTimeout(() => {
                // window.location.href = "/verify-account";
                window.location.href = "/login";
            }, 2000);
        } else {
            // res.data?.data
            Toast(res.data.message, "error");
        }

    } catch (error) {
        console.log(error.response)
        dispatch({
            type: constants.FAILURE,
            errors: error.response
        })
    }
}


export const fetchAgents = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })

    // console.log("Action of fetching agents")
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/agents`);
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_AGENTS,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }
        // console.log("Action of fetching agents...", res)

    } catch (error) {
        console.log(error.response)

    }
}


// interface BaseByID {
//     id?: number
// }
export const fetchBaseCommissions = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })


    console.log("Action of fetching base_commissions")
    try {

        // dispatch({
        //     type: constants.LIST_BASE_COMMISSIONS,
        //     payload: []
        // })    

        let res = {}
        if(payload.id){
            res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superagent/agents/${payload.id}/commissions`);
        }else{
            res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/base/commissions`);
        }
        if (res.data && res.data.code === 200) {
            dispatch({
                type: constants.LIST_BASE_COMMISSIONS,
                payload: res.data?.data
            })
        } else {
            Toast(res.data.message, "error");
        }
        console.log("Action of fetching base_commissions...", res)

    } catch (error) {
        console.log(error.response)

    }
}



export const allocateCommissions = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOADING })


    console.log("Action of allocating commissions :", payload)
    try {

        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superagent/agents/${payload.id}/commissions`, payload.payload);
        

        if (res.data && res.data.code === 200) {
            payload["status"] = "success"
            Toast(res.data.message, "success");
            dispatch({ type: constants.LOADING_STOP })
            return payload
        } else {
            payload["status"] = "failed"
            Toast(res.data.message, "error");
            dispatch({ type: constants.LOADING_STOP })
            console.log("Action of allocating InError :", res)
            return payload
        }
        console.log("Action of allocating commissions...", res)

    } catch (error) {
        console.log(error.response)
        console.log("Action of allocating Error :", error)
        dispatch({ type: constants.LOADING_STOP })
    }
}

export const resetAuth = () => {
    return {
        type: constants.RESET_AUTH,
    }
}