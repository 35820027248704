import constants from '../constants/ActionTypes';

const initialState = {
    loading: false,
    sales_report: null,
}

const transactionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case constants.LOADING:
            return { ...state, loading: true }
        case constants.REQUEST:
            return { ...state, }
        case constants.LIST_TRANSACTIONS:
            return { ...state, transactions: actions.payload, loading: false }
        case constants.LIST_TRANSACTIONS_ID:
            return { ...state, transactions: actions.payload, loading: false }
        case constants.UNIVERSAL_REQUEST:
            return { ...state, universal_request: actions.payload, loading: false }
        case constants.SALES_REPORT:
            return { ...state, sales_report: actions.payload, loading: false }
        default:
            return state;
    }
}

export default transactionReducer;