import React from 'react';
import './terms-policy.css';

function Policy() {
    return (
        <div className="tp-container">
            <img src={require("../../components/asset/dark-logo.svg").default} style={{float:'right', display:'inline-block', }} alt="" />
            <br/>
            <br/>
            <h5>INTRODUCTION</h5>
            <span>
                <p>This website, <a href="https://www.airvend.ng" target="_blank">https://www.airvend.ng</a> (“Platform”), is operated by Callphone Limited
                (hereinafter referred to as “we”, “our”, “us” or “Callphone”).
                We are committed to the protection of all personal information that you may provide
                to us.</p>
                 <p>This privacy policy (“Policy”), together with our terms and conditions [INSERT
                AS LINK TO PLATFORM TERMS AND CONDITIONS] and any other documents
                referred to on it, sets out the basis on which any personal data we collect from you,
                or that you provide to us, will be processed by us.</p>
                <p>By visiting and accessing our Platform, you are accepting and consenting to the
                terms and conditions in this Policy. If you do not agree to this Policy, please do not
                access our Platform.</p>
            </span>

            <h5>THE INFORMATION THAT WE COLLECT FROM YOU</h5>
            <span>We may receive, use, store and transfer different kinds of personal data about you
                including the following:</span>
            <ul>
                <li>Identity Data: includes [first name, last name, maiden name, username or
                    similar identifier, marital status, title, date of birth, gender].</li>
                <li>Contact Data: includes [billing address, delivery address, email address and
                    telephone numbers].
                </li>
                <li>Financial Data: includes [bank account and payment card details].</li>
                <li>Transaction Data: includes [details about your transactions].</li>
                <li>Device Data: includes [information on the devices with which you access the
                    platform].
                </li>
                <li>Profile Data: includes [your username and password, picture, transaction
                    history, your interests, preferences, feedback and survey responses].</li>
                <li>Usage Data: includes [details of your use of our services ].</li>
                <li>Marketing and Communications Data: includes [your preferences in
                    receiving marketing from us and our third parties and your communication
                    preferences].
                </li>
                <li>Location Data: includes [your current location disclosed by GPS technology
                    or any other location territory].
                </li>
            </ul>
            <p>
                We may also collect, use and share aggregated data such as statistical or
                demographic data for any purpose. Aggregated data could be derived from your
                personal data but is not considered personal data as this data will not directly or
                indirectly reveal your identity. For example, we may aggregate your usage data to
                calculate the percentage of users accessing a specific Platform feature.
            </p>

            <h5>INFORMATION FROM OTHER SOURCES</h5>
            <p>
                This is information we receive about you if you use any of the other Platforms we

                operate or the other services we provide.
                When you create an account with us, we may collect information, including nonpublic
                personal information, about you from third party service providers in order to verify
                your identity and for fraud prevention, including your prior addresses and names.
            </p>
            <h5>COOKIES</h5>
            <p>We use cookies to distinguish you from other users of our Platform and to remember
                your preferences. This helps us to provide you with a good experience when you
                access our Platform and allows us to improve our site.
                You can set your browser to refuse all or some browser cookies or to alert you when
                Platforms set or access cookies. If you disable or refuse cookies, please note that
                some parts of this Platform may become inaccessible or not function properly.</p>

            <h5>THE PURPOSE FOR WHICH WE WILL USE YOUR DATA</h5>
            <p>The information that we collect from you - We may use information held about
                you in the following ways:</p>

            <ul>
                <li>to carry out our obligations arising from any contracts entered into between
                    you and us and to provide you with the information, products and services that
                    you request from us;</li>
                <li>to provide you with information about other goods and services we offer that
                    are similar to those that you have already purchased or enquired about;</li>
                <li>to provide you, or permit selected third parties to provide you, with information
                    about goods or services we feel may interest you. If you are an existing
                    customer, we will only contact you by electronic means (e-mail or SMS) with
                    information about goods and services similar to those which were the subject
                    of a previous sale or negotiations of a sale to you. If you are a new customer,
                    and where we permit selected third parties to use your data, we (or they) will
                    contact you by electronic means only if you have consented to this. If you do
                    not want us to use your data in this way, or to pass your details on to third
                    parties for marketing purposes, please tick the relevant box situated on the
                    form on which we collect your data;</li>
                <li>to ensure that content from our site is presented in the most effective manner
                    for you and to notify you about changes to our service;</li>
                <li>to administer our site and for internal operations, including troubleshooting,
                    data analysis, testing, research, statistical and survey purposes;</li>
                <li>to measure or understand the effectiveness of advertising we serve to you
                    and others, and to deliver relevant advertising to you; and</li>
                <li>to make suggestions and recommendations to you and other users of our site
                    about goods or services that may interest you or them.
                    Information we receive from other sources - We will combine this information with

                    information you give to us and information we collect about you. We will use this
                    information and the combined information for the purposes set out above (depending
                    on the types of information we receive).</li>
            </ul>

            <h5>DISCLOSURE OF YOUR PERSONAL INFORMATION</h5>
            <p>You agree that we have the right to share your personal information:</p>

            <ul>
                <li>with any of our affiliated companies and service providers;</li>
                <li>with financial service providers, including the financial institutions identified in your
                    cardholder bank agreement that provide banking services in connection with your
                    account;</li>
                <li>with another user, when you sign up for our services via a referral link. The user that
                    referred you may receive information indicating that you have enrolled with us;</li>
                <li>with non-financial companies, such as email service providers that perform
                    marketing services on our behalf, and fraud prevention service providers that use the
                    information to provide services to us;</li>
                <li>with a non-affiliated third-party to access and transmit your personal and financial
                    information from a relevant financial institution. You grant the third-party the right,
                    power, and authority to access and transmit this information according to terms of
                    their privacy policy;</li>
                <li>with selected third parties including business partners, suppliers and sub-contractors
                    for the performance of any contract we enter into with them or you;</li>
                <li>with third parties to whom we may choose to sell, transfer or merge parts of our
                    business or our assets or alternatively, acquire all or parts of their businesses;</li>
                <li></li>
                <li>in response to a request for information, if we are required by, or we believe
                    disclosure is in accordance with, any applicable law, regulation or legal process; and</li>
                <li>with relevant law enforcement officials or other third parties, such as investigators or
                    auditors, if we believe it is appropriate to investigate fraud.</li>
            </ul>

            <h5>TRANSFER TO THIRD PARTIES</h5>
            <p>We may provide other users with your personal information to complete a
                transaction. Such information may include your name, account identity, contact
                details or such additional information required information for the transaction.</p>

            <h5>THIRD PARTY LINKS</h5>
            <p>Our Platform may, from time to time contain links, to and from the Platforms of our
                partner networks, advertisers and affiliates. Please note that these Platforms and
                any services that may be accessible through them have their own privacy policies
                and that we do not accept any responsibility or liability for these policies or for any
                personal data, such as contact and location data, which may be collected through

                these Platforms or services. Please check these policies before you submit any
                personal data to these Platforms or use these services.</p>

            <h5>CHANGES TO PERSONAL INFORMATION</h5>
            <p>It is imperative that your data with us is current and accurate. You may request for
                the rectification of any personal data that we hold about you so as to enable you to
                correct any incomplete or inaccurate data we hold about you. We may, however,
                need to verify the accuracy of the new data you provide to us.</p>

            <h5>DATA SECURITY AND RETENTION</h5>
            <p>We deploy strict physical, electronic, and administrative security measures to protect
                your information from access by unauthorised persons, against unlawful processing
                and foreseeable hazards and breaches when online.
                We will retain your data for as long as necessary for the said purpose of the
                processing, and after that, we will keep your data as long as the law requires.</p>

            <h5>YOUR LEGAL RIGHTS</h5>
            <p>You have the right to:</p>

            <ul>
                <li>Request access to your personal data (commonly known as a “data subject
                    access request”). This enables you to receive a copy of the personal data we
                    hold about you and to check that we are lawfully processing it.</li>
                <li>Request correction of the personal data that we hold about you. This
                    enables you to have any incomplete or inaccurate data we hold about you
                    corrected, though we may need to verify the accuracy of the new data you
                    provide to us.</li>
                <li>Request erasure of your personal data. This enables you to ask us to delete
                    or remove personal data where there is no good reason for us continuing to
                    process it. You also have the right to ask us to delete or remove your personal
                    data where you have successfully exercised your right to object to processing
                    (see below), where we may have processed your information unlawfully or
                    where we are required to erase your personal data to comply with local law.
                    Note, however, that we may not always be able to comply with your request of
                    erasure for specific legal reasons which will be notified to you, if applicable, at
                    the time of your request.</li>
                <li>Object to processing of your personal data where we are relying on a
                    legitimate interest (or those of a third party) and there is something about your
                    particular situation which makes you want to object to processing on this
                    ground as you feel it impacts on your fundamental rights and freedoms. You
                    also have the right to object where we are processing your personal data for
                    direct marketing purposes. In some cases, we may demonstrate that we have
                    compelling legitimate grounds to process your information which override your
                    rights and freedoms.</li>
                <li>Request restriction of processing of your personal data. This enables you
                    to ask us to suspend the processing of your personal data in the following
                    scenarios:
                    o If you want us to establish the data’s accuracy.
                    o Where our use of the data is unlawful but you do not want us to erase
                    it.
                    o Where you need us to hold the data even if we no longer require it as
                    you need it to establish, exercise or defend legal claims.
                    o You have objected to our use of your data but we need to verify
                    whether we have overriding legitimate grounds to use it.</li>
                <li>Request the transfer of your personal data to you or to a third party. We will
                    provide to you, or a third party you have chosen, your personal data in a
                    structured, commonly used, machine-readable format. Note that this right only
                    applies to automated information which you initially provided consent for us to
                    use or where we used the information to perform a contract with you.</li>
                <li>Withdraw consent at any time where we are relying on consent to process
                    your personal data. However, this will not affect the lawfulness of any
                    processing carried out before you withdraw your consent. If you withdraw your
                    consent, we may not be able to provide certain products or services to you.
                    We will advise you if this is the case at the time you withdraw your consent.</li>
            </ul>

            <h5>CHANGES TO THIS POLICY</h5>
            <p>We may periodically update this Policy without notifying you, but we will display a
                conspicuous notice on this Platform to inform you of any significant changes to our
                Policy.
            </p>
            <p>Please check back frequently to see whether there are any updates or changes to
                this Policy.</p>

            <h5>CONTACT US</h5>
            <p>If you have any questions about this privacy policy or our privacy practices, please
                contact our customer service in the following ways:</p>

            <p>Full name of legal entity: Callphone Limited</p>

            <p>Email address: info@callphoneng.com</p>

            <p>Postal address: Plot 1132, Cadastral Zone, Katampe District, Opposite Bon Hotels
                Grand Towers, Abuja.</p>
            <span>Telephone number: (+234)9038864341</span>
        </div>
    );
}

export default Policy;