import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';

interface TableProps {
    data: any[],
    columns: string[],
    rowKey?: string,
    onClick: (e: any) => void
}

function Table2(props: TableProps) {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    //   ref={node => {
                    //     searchInput = node;
                    //   }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                // setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText("");
    };

    let columns: any[] = [];
    props?.columns.map(e => (
        columns.push({
            title: e,
            dataIndex: e.toLowerCase(),
            key: e.toLowerCase(),
            ...getColumnSearchProps(e.toLowerCase()),
            render: e.toLowerCase() === "status" ? (val: any) => {
                const check_props = val === "failure" ? "text-danger" : val === "success" ? "text-success" : "";
                return <div className={check_props}>{val}</div>
            } : undefined
        })
    ))

    return (
        <Table
            columns={columns}
            dataSource={props?.data}
            style={{ fontFamily: 'Rubik' }}
            rowKey="id"
            pagination={false}
            onRow={(e: any) => ({
                onClick: () => props?.onClick(e)
            })}
        />
    )
}

export default Table2;