import React from 'react';
import './css/loader.css';

interface Props {
    className?: string,
    type?: string
}

export default function Loader(props: Props) {
    const { className, type } = props;
    return <>
        {type !== "btn" && <div className="loader-container">
            <div className={`loader-custom ${className || "loader-sm"}`}></div>
        </div>}

        {type === "btn" && <div className={"loader-custom loader-sm"}></div>}
    </>
}