import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Button from '../../components/button/button';
import './css/register-verify-phone.css'
import SelectboxWithIcon from '../../components/selectbox-with-icon/selectbox-with-icon';
import back_arrow from '../../components/asset/bill-payments/back-arrow.svg';
import Otp from '../../components/otp/otp';
import { verifyOTP, resetAuth } from '../../actions/auth';
import history from '../../config/history';

interface Props {
    returningUser?: boolean,
    verifyOTP: (otp: string) => void,
    resetAuth: () => void,
    auth: AuthProps
}

function RegisterVerifyPhone(props: Props) {
    let navigation = history();
    const {
        auth: {
            input_data: { phone_number }
        }
    } = props;

    const [loading, setLoading] = useState(false);
    const [otp, setOTP] = useState("");

    const handleSubmit = async () => {
        setLoading(true);
        const { verifyOTP } = props;
        await verifyOTP(otp);
        setLoading(false);
    }

    return (
        <>
            <div className="box-management">
                <div className="logo-area-reg">
                    <img src={require("../../components/asset/dark-logo.svg").default} alt="" className="login-logo" />
                </div>
                <div className="central-area-auth">
                    <div className="text-area">
                        Verify your phone number
                    </div>

                    <div className="text-info-area">
                        <p>{`We just sent a six(6) digits one time password to your phone ${phone_number}. enter the otp below to verify your phone number.`}</p>
                    </div>

                    {/* <div className="text-success">{localStorage.getItem("otp")}</div> */}

                    <div className="text-form-area form-otp">
                        <Otp setVal={(e: any) => setOTP(e)} />
                    </div>

                    <div className="submit-button">
                        <Button
                            btn_name="Verify phone"
                            color="btn-blue"
                            loading={loading}
                            onClick={handleSubmit}
                        />
                    </div>

                    <br />
                    <div onClick={() => {
                        props.resetAuth()
                        navigation.goBack()
                    }} className="back-text"><img src={back_arrow} /> Go Back</div>

                </div>
            </div>
        </>
    );
}

interface AuthProps {
    input_data: {
        phone_number: string
    }
}

interface RegisterProps {
    auth?: AuthProps,
}

function mapStateToProps(state: RegisterProps, ownProps: Object) {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    verifyOTP,
    resetAuth
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerifyPhone);