import React from 'react';
import Loader from '../loader/loader';

interface Props {
    resolving?: Boolean,
    resolvedData?: String
}

export default function Resolver(props: Props) {
    const {
        resolvedData,
        resolving
    } = props;

    return (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ marginRight: 10 }}>{resolving && <Loader className="loader-md" />}</div>
            {!resolving && <div className="resolved-text">{resolvedData}</div>}
        </div>
    )
}