export function init() {
    resizeComp();
    
    window.onresize = function () {
        resizeComp();
    }
}

function resizeComp(){
    var img = document.getElementById('banner');
        var content = document.getElementById("overview-content");
        
        var width = img?.clientWidth;
        var height = img?.clientHeight;

        content?.setAttribute('style', `width:100%!Important`);
        content?.setAttribute('style', `height:${height}px!Important`);
}